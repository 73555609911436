import React from "react";
import Nav from "../components/elements/Nav";
import { Link, useLocation } from "react-router-dom";

const data = [{
    text: 'Fundaciones',
    link: '/fundaciones',
    active: '/fundaciones'
},
{
    text: 'Componentes',
    link: <Link to='/componentes'></Link>,
    active: '/componentes'
},
{
    text: 'Campañas',
    link: <Link to='/campanas'></Link>,
    active: '/campanas'
}]

export default function Header() {

    const { pathname } = useLocation();

    return (
        <Nav data={data} LogoContent={<Link to='/'></Link>} pathname={pathname}></Nav>
    );
}
