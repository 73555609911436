import React from "react";
// Layout
import Main from "../../../components/layout/Main";

// Class
import {PreviewCode} from "../../../components/elements/PreviewCode";

// Component
import Tag from "../../../components/elements/Tag";
import BoxCode from "../../../components/elements/BoxCode";

export default function Tags() {
    const code1 = `
import Tag from "../../../components/elements/Tag";

export default function TagExample() {
    return (
        <>
            <Tag>tag 1</Tag>
            <Tag>tag 2</Tag>
            <Tag>tag 3</Tag>
        </>
    );
}`;

    const code2 = `
import Tag from "../../../components/elements/Tag";

export default function TagExample() {
    return (
        <>
            <Tag size='small'>tag 1</Tag>
            <Tag size='small'>tag 2</Tag>
            <Tag size='small'>tag 3</Tag>
        </>
    );
}`;

    const code3 = `
import Tag from "../../../components/elements/Tag";

export default function TagExample() {
    return (
        <>
            <Tag size='big'>tag 1</Tag>
            <Tag size='big'>tag 2</Tag>
            <Tag size='big'>tag 3</Tag>
        </>
    );
}`;

    const code4 = `
import Tag from "../../../components/elements/Tag";

export default function TagExample() {
    return (
        <>
            <Tag size='small' backgroundColor='#606060' color='#fff'>tag 1</Tag>
            <Tag size='small' backgroundColor='#71d44c' color='#fff'>tag 2</Tag>
            <Tag size='small' backgroundColor='#ec7029' color='#fff'>tag 3</Tag>
            <Tag size='small' backgroundColor='#1f8ee7' color='#fff'>tag 3</Tag>
            <Tag size='small' backgroundColor='#8b2fb1' color='#fff'>tag 3</Tag>
        </>
    );
}`;

    const code5 = `
import Tag from "../../../components/elements/Tag";

export default function TagExample() {
    return (
        <>
            <Tag size='small'>tag 1</Tag>
            <Tag size='small'>tag 2</Tag>
            <Tag size='small'>tag 3</Tag>
        </>
    );
}`;

    const code6 = `
import Tag from "../../../components/elements/Tag";

export default function TagExample() {
    return (
        <div className="flex col g15">
            <Tag size='small'>tag 1</Tag>
            <Tag size='small'>tag 2</Tag>
            <Tag size='small'>tag 3</Tag>
        </div>
    );
}`;

    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title="Tag" classBox="gray6 g15" code={code1}>
                    <Tag>tag 1</Tag>
                    <Tag>tag 2</Tag>
                    <Tag>tag 3</Tag>
                </BoxCode>

                <BoxCode title="Tag Small" classBox="gray6 g15" code={code2}>
                    <Tag size="small">tag 1</Tag>
                    <Tag size="small">tag 2</Tag>
                    <Tag size="small">tag 3</Tag>
                </BoxCode>

                <BoxCode title="Tag Big" classBox="gray6 g15" code={code3}>
                    <Tag size="big">tag 1</Tag>
                    <Tag size="big">tag 2</Tag>
                    <Tag size="big">tag 3</Tag>
                </BoxCode>

                <BoxCode title="Tag Color" classBox="gray6 g15" code={code4}>
                    <Tag size="small" backgroundColor="#606060" color="#ffffff">
                        tag 1
                    </Tag>
                    <Tag size="small" backgroundColor="#71d44c" color="#ffffff">
                        tag 2
                    </Tag>
                    <Tag size="small" backgroundColor="#ec7029" color="#ffffff">
                        tag 3
                    </Tag>
                    <Tag size="small" backgroundColor="#1f8ee7" color="#ffffff">
                        tag 3
                    </Tag>
                    <Tag size="small" backgroundColor="#8b2fb1" color="#ffffff">
                        tag 3
                    </Tag>
                </BoxCode>

                <BoxCode title="Tag Row" classBox="gray6 g15" code={code5}>
                    <Tag size="small">tag 1</Tag>
                    <Tag size="small">tag 2</Tag>
                    <Tag size="small">tag 3</Tag>
                </BoxCode>

                <BoxCode title="Tag Column" classBox="gray6" code={code6}>
                    <div className="flex col g15">
                        <Tag size="small">tag 1</Tag>
                        <Tag size="small">tag 2</Tag>
                        <Tag size="small">tag 3</Tag>
                    </div>
                </BoxCode>
            </div>
        </Main>
    );
}
