// Router
import {Navigate, Route, Routes} from "react-router-dom";
import React from "react";
// Pages
import Botones from "../pages/Botones";
import Listas from "../pages/Listas";
import Cards from "../pages/Cards";
import Navbar from "../pages/Navbar";
import Tags from "../pages/Tags";
import Listado from "../pages/Listados";
import Inputs from "../pages/Inputs";
import Tablas from "../pages/Tablas";
import ExampleTableV2 from "../pages/TableV2";
import Sidebar from "../pages/Sidebar";
import Dropdown from "../pages/Dropdown";
import ProgressBars from "../pages/ProgressBars";
import Breadcrumb from "../pages/Breadcrumb";
import Switch from "../pages/Switch";
import ModalExample from "../pages/Modal";
import UptimeExample from "../pages/Uptime";
import TooltipExample from "../pages/Tooltip";
import GridStatus from "../pages/GridStatus";
import BoxLoginExample from "../pages/BoxLogin";
import StaticSidebar from "../pages/StaticSidebar";
import PreloaderExample from "../pages/Preloader";
import Pruebas from "../pages/Pruebas";
import TabsExample from "../pages/Tabs";
import RadioLabel from "../pages/RadioLabel";

export default function ComponentesRoutes() {
    return (
        <Routes>
            
            <Route path="/botones" element={<Botones />} />
            <Route path="/cards" element={<Cards />} />
            <Route path="/navbar" element={<Navbar />} />
            <Route path="/sidebar" element={<Sidebar />} />
            <Route path="/dropdown" element={<Dropdown />} />
            <Route path="/listas" element={ <Listas /> } />
            <Route path="/tablas" element={ <Tablas /> } />
            <Route path="/tableV2" element={ <ExampleTableV2 /> } />
            <Route path="/tags" element={ <Tags /> } />
            <Route path="/tabs" element={ <TabsExample /> } />
            <Route path="/listados" element={ <Listado /> } />
            <Route path="/breadcrumb" element={ <Breadcrumb /> } />
            <Route path="/inputs" element={ <Inputs /> } />
            <Route path="/progress-bar" element={ <ProgressBars /> } />
            <Route path="/switch" element={ <Switch /> } />
            <Route path="/Modal" element={ <ModalExample /> } />
            <Route path="/uptime" element={ <UptimeExample /> } />
            <Route path="/tooltip" element={ <TooltipExample /> } />
            <Route path="/grid-status" element={ <GridStatus /> } />
            <Route path="/box-login" element={ <BoxLoginExample /> } />
            <Route path="/preloader" element={ <PreloaderExample /> } />
            <Route path="/static-sidebar" element={ <StaticSidebar /> } />
            <Route path="/radio-label" element={ <RadioLabel /> } />
            <Route path="/zaid" element={ <Pruebas /> } />
            <Route path="/" element={<Navigate to={"botones"} />} />

        </Routes>
    );
}
