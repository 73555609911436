import React from "react";
import { useState } from "react";
import { NumericFormat } from "react-number-format";

export default function Col({
  children,
  className,
  order,
  filter,
  filterData,
  type,
  actionOrder,
  actionFilter,
  priority,
  isHidden=0,
  max,
  colSpan,
  textAlign,
  onClick,
  getOrderValue = () => {},
  key='',
  size=0,
  maxSize=0
}) {
  const [isOrder, setIsOrder] = useState(0);

  function getThisTypes() {
    switch (type) {
      case "icon":
        return getElement(children, "icon");

      case "btn":
        return getElement(children, "btn");

      case "money":
        return getElement(
          new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
            minimumFractionDigits: 0,
          }).format(children.slice(0, max)),
          "money"
        );

      case "numero":
        return getElement(
          <NumericFormat
            value={children.slice(0, max)}
            displayType="text"
            thousandSeparator={true}
          />,
          "numero"
        );

      default:
        return getElement(
          typeof children == "string" ? children.slice(0, max) : children,
          ""
        );
    }
  }

  function getElement(children) {
    if (order) {
      return (
        <td
          size={size}
          maxSize={maxSize}
          colSpan={colSpan}
          className={`cursor-pointer ${
            priority != undefined ? priority : "baja"
          } td ${type !== undefined ? type : ""} ${
            className !== undefined ? className : ""
          }`}
          onClick={() => {
            actionOrder();
            setIsOrder(isOrder > 1 ? 0 : isOrder + 1);
            getOrderValue((isOrder > 1 ? 0 : isOrder + 1), key);
          }}
        >
          {/* <td colSpan={colSpan} style={{ minWidth: size != undefined ?  typeof size == 'string' ? size : `${size}px` : 'auto' }} className={`cursor-pointer ${priority != undefined ? priority : 'baja td'} ${type !== undefined ? type : ''} ${className !== undefined ? className : ''}`} onClick={() => {actionOrder(); setIsOrder(isOrder > 1 ? 0 : isOrder + 1)}}> */}
          <div className="td-content">
            {children}
            {/* ALEXIS: AQUÍ CAMBIAR LA LÓGICA POR DEFAULT: SOLAMENTE ARROW, ASC, ARROW+ASC, O DESC, ARROW+DESC */}
            <div className={`table-arrow`}>
              <svg
                viewBox="0 0 86.6 75"
                className={`${isOrder == 1 ? "on" : ""}`}
              >
                <polygon points="0.2,74.9 43.3,0.2 86.4,74.9" />
                <path d="M43.3,0.5l42.9,74.2H0.4L43.3,0.5 M43.3,0L0,75h86.6L43.3,0L43.3,0z" />
              </svg>
              <svg
                viewBox="0 0 86.6 75"
                className={`${isOrder == 2 ? "on" : ""}`}
              >
                <polygon points="0.2,74.9 43.3,0.2 86.4,74.9" />
                <path d="M43.3,0.5l42.9,74.2H0.4L43.3,0.5 M43.3,0L0,75h86.6L43.3,0L43.3,0z" />
              </svg>
            </div>
            {/* <div className="arrow" style={ open ? {transform: 'rotate(180deg) translateY(50%)'} : {}}></div> */}
            {/* <svg className='icon' stroke="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path>
                        </svg> */}
          </div>
        </td>
      );
    }

    return (
      <td
        size={size}
        maxSize={maxSize}
        onClick={() => (onClick != undefined ? onClick() : "")}
        colSpan={colSpan}
        className={`${className !== undefined ? className : ""} ${
          priority != undefined ? priority : "baja"
        } td ${type !== undefined ? type : ""} text-${textAlign ?? "left"}`}
      >
        {children}
      </td>
    );
    {
      /* return <td colSpan={colSpan} style={{ minWidth: size != undefined ? typeof size == 'string' ? size : `${size}px` : 'auto' }} className={`${className !== undefined ? className : ''} ${priority != undefined ? priority : 'baja td'} ${type !== undefined ? type : ''} text-${textAlign ?? 'left'}`}>{children}</td>; */
    }
  }

  return getThisTypes();
  // return <td>{children}</td>;
}
