
import React, { createElement, useEffect, Fragment, useState, useRef } from "react";
import Dropdown from "./Dropdown";

export default function GridStatus({ data = [], isResize=true, textAlign = 'center', id = 'gs-id', gap = 10, border = true, paddingBorder = 0, columns, width, padding = 10, columnWidth, filter = [] }) {
    
    const gsRow = useRef(null);

    const [column, setColumn] = useState(columns + 1);
    const [filtros, setFiltros] = useState([]);
    const [filtrosValue, setFiltrosValue] = useState([]);
    const [filtroId, setFiltrosId] = useState(0);
    const [gridWidth, setGridWidth] = useState(0);
    
    useEffect(() => {
        columns == undefined ? setColumn(calcGrid()) : setColumn(columns);
    }, [columns])

    useEffect(() => {
        let w = [];
        Array.from(gsRow.current.children).forEach((item) => {w.push(item.clientWidth)})
        setGridWidth(Math.max(...w));
    }, [])

    useEffect(() => {
        if (isResize) {
            if (column != undefined) {
                new ResizeObserver(([e]) => {resizeGrid(e.target.clientWidth)}).observe(gsRow.current);
            }   
        }
    }, [column, data])

    useEffect(() => {
        
        if (filter.length != 0 ) {
            let dataDropdow = [{value: 'Todos', id:0}];

            data.forEach((item, index) => {
                dataDropdow.push({
                    value: item.value ?? item.filterValue,
                    id: ++index
                });
            });

            setFiltros([]);

            setFiltros(filtros => [...filtros,
                <div className="gs-content-filter">
                    <Dropdown items={dataDropdow} onSelectOption={(item) => setFiltrosValue([item.value])} id={filtroId} />
                </div>
            ]);   
        }
    }, [filtroId])

    function resizeGrid(width) {
        let col = Math.floor(width / (gridWidth + gap))
        if (col < columns) {
            setColumn(col);
        }
    }
    
    function calcGrid() {
        return Math.ceil(Math.sqrt(data.length));
    }

    function handleFilter(value, index) {
        setFiltrosValue([value])
        setFiltrosId(index)
    }

    function handleClickContent(item, index) {
        if (item.onFilter != undefined) {
            if (item.onFilter) {
                handleFilter(item.value ?? item.filterValue, index + 1)
            }
        }

        if (item.content.props.onClick != undefined) {
            item.content.props.onClick()
        }
    }

    return (
        <div className="gs-row" ref={gsRow} style={{
            gridGap: typeof gap == 'string' ? gap : `${gap}px`,
            gridTemplateColumns: `repeat(${column}, 1fr)`,
            minWidth: typeof width == 'string' ? width : `${width}px` 
        }}>
            {data.filter(el => {
                if (filtrosValue != 0 && !filtrosValue.includes('Todos')) {
                    return filtrosValue.includes(el.value ?? el.filterValue)
                } else {
                    return el
                }
            }).map((item, index) => {
                if (item.content == undefined) {
                    if (item.link != undefined) {
                        if (typeof item.link == 'object') {
                            return createElement(item.link.type, {
                                ...item.link.props,
                                className: `gs-column text-${textAlign} ${item.link != undefined && item.link != '' ? 'cursor-pointer' : ''} ${item.status ?? ''} ${!item.color?.includes('#') && !item.color?.includes('rgb') ? item.color : ''} ${!item.background?.includes('#') && !item.background?.includes('rgb') ? item.background : ''}`,
                                style: {
                                    color: !item.color?.includes('#') && !item.color?.includes('rgb') ? undefined : item.color,
                                    backgroundColor: !item.background?.includes('#') && !item.background?.includes('rgb') ? undefined : item.background,
                                    padding: typeof padding == 'string' ? padding : `${padding}px`,
                                    width: typeof columnWidth == 'string' ? columnWidth ?? '' : `${columnWidth}px`,
                                },
                                onClick: () => item.link.props.onClick ?? item.onFilter != undefined ? item.onFilter ? handleFilter(item.value, index + 1) : '' : item.onClick
                            },
                            <div className="text">{item.value ?? 'N/A'}</div>);
                        } else {
                            return (
                                <a onClick={() => item.onFilter != undefined ? item.onFilter ? handleFilter(item.value, index + 1) : '' : item.onClick} href={item.link} key={index} className={`gs-column text-${textAlign} ${item.link != undefined && item.link != '' ? 'cursor-pointer' : ''} ${item.status ?? ''} ${!item.color?.includes('#') && !item.color?.includes('rgb') ? item.color : ''} ${!item.background?.includes('#') && !item.background?.includes('rgb') ? item.background : ''}`} style={{ 
                                    color: !item.color?.includes('#') && !item.color?.includes('rgb') ? undefined : item.color,
                                    backgroundColor: !item.background?.includes('#') && !item.background?.includes('rgb') ? undefined : item.background,
                                    padding: typeof padding == 'string' ? padding : `${padding}px`,
                                    width: typeof columnWidth == 'string' ? columnWidth ?? '' : `${columnWidth}px`,
                                }}>
                                    <div className="text">{item.value ?? 'N/A'}</div>
                                </a>
                            );
                        }
                    } else {
                        return (
                            <div onClick={() => item.onFilter != undefined ? item.onFilter ? handleFilter(item.value, index + 1) : '' : item.onClick} key={index} className={`gs-column text-${textAlign} ${item.link != undefined && item.link != '' || item.onClick != undefined ? 'cursor-pointer' : ''} ${item.status ?? ''} ${!item.color?.includes('#') && !item.color?.includes('rgb') ? item.color : ''} ${!item.background?.includes('#') && !item.background?.includes('rgb') ? item.background : ''}`} style={{
                                color: !item.color?.includes('#') && !item.color?.includes('rgb') ? undefined : item.color,
                                backgroundColor: !item.background?.includes('#') && !item.background?.includes('rgb') ? undefined : item.background,
                                padding: typeof padding == 'string' ? padding : `${padding}px`,
                                width: typeof columnWidth == 'string' ? columnWidth ?? '' : `${columnWidth}px`,
                            }}>
                                <div className="text">{item.value ?? 'N/A'}</div>
                            </div>
                        );
                    }   
                } else {
                    return createElement(
                        item.content.type,
                        {
                            ...item.content.props,
                            className: `gs-column ${item.content.props.className ?? ''}`,
                            style: {
                                color: !item.color?.includes('#') && !item.color?.includes('rgb') ? undefined : item.color,
                                backgroundColor: !item.background?.includes('#') && !item.background?.includes('rgb') ? undefined : item.background,
                                padding: typeof padding == 'string' ? padding : `${padding}px`,
                                width: typeof columnWidth == 'string' ? columnWidth ?? '' : `${columnWidth}px`,
                            },
                            onClick: () => handleClickContent(item, index)
                        }
                    );
                }
            })}
        </div>
    );
}