import React, { useEffect, useRef, useState } from "react";

export default function Switch({ className, type, onChange, isChecked, colorFalse, colorTrue, before, after, width, heigh, background, backgroundChecked }) {

    const switchElement = useRef(null);
    const contentSwitch = useRef();

    const [isCheck, setIsCheck] = useState(false);

    useEffect(() => {
        setIsCheck(isChecked);
    }, [isChecked])

    useEffect(() => {
        if (!isCheck) {
            if (colorFalse != undefined) {
                !colorTrue.includes('#') && !colorTrue.includes('rgb') ? switchElement.current.classList.remove(colorTrue) : switchElement.current.style.backgroundColor = '';
                !colorFalse.includes('#') && !colorFalse.includes('rgb') ? switchElement.current.classList.add(colorFalse) : switchElement.current.style.backgroundColor = colorFalse;
            }
        } else {
            if (colorTrue != undefined) {
                !colorFalse.includes('#') && !colorFalse.includes('rgb') ? switchElement.current.classList.remove(colorFalse) : switchElement.current.style.backgroundColor = '';
                !colorTrue.includes('#') && !colorTrue.includes('rgb') ? switchElement.current.classList.add(colorTrue) : switchElement.current.style.backgroundColor = colorTrue;
            }
        }
    }, [isCheck])

    useEffect(() => {
        if (contentSwitch.current != undefined) {
            contentSwitch.current.style.setProperty(`--width`, width != undefined ? typeof width == 'string' ? width : `${width}px` : null)
            contentSwitch.current.style.setProperty(`--height`, heigh != undefined ? typeof heigh == 'string' ? heigh : `${heigh}px` : null)
            contentSwitch.current.style.setProperty(`--background`, background != undefined ? typeof background == 'string' ? background : `${background}px` : null)
            contentSwitch.current.style.setProperty(`--background-checked`, backgroundChecked != undefined ? typeof backgroundChecked == 'string' ? backgroundChecked : `${backgroundChecked}px` : null)
        }
    }, [width, heigh, background, backgroundChecked])
    

    function handleChange() {
        if (onChange != undefined) {
            onChange();   
        }
        setIsCheck(!isCheck);
    }


    switch (type) {
        case 'code':
            return (
                <label className="switch-code">
                    <input type="checkbox" defaultChecked={isCheck} onChange={handleChange} />
                    <div className="slider">
                        <span className="before">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path>
                            </svg>
                            Preview
                        </span>
                        <span className="after">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <path d="m7.375 16.781 1.25-1.562L4.601 12l4.024-3.219-1.25-1.562-5 4a1 1 0 0 0 0 1.562l5 4zm9.25-9.562-1.25 1.562L19.399 12l-4.024 3.219 1.25 1.562 5-4a1 1 0 0 0 0-1.562l-5-4zm-1.649-4.003-4 18-1.953-.434 4-18z"></path>
                            </svg>
                            Code
                        </span>
                    </div>
                </label>
            );

        case 'text':
            return (
                <label className="switch-text" ref={contentSwitch}>
                    <input type="checkbox" defaultChecked={isCheck} onChange={handleChange} />
                    <div className="slider">
                        <span className="before">{before}</span>
                        <span className="after">{after}</span>
                    </div>
                </label>
            );
    
        default:
            return (
                <label className="switch">
                    <input type="checkbox" checked={isCheck} onChange={handleChange} />
                    <span ref={switchElement} className={`slider ${colorFalse == undefined && colorTrue == undefined ? 'default' : ''} ${className != undefined ? className : ''}`}></span>
                </label>
            );
    }
}