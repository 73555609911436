import { useEffect, useState } from "react";
import BoxCode from "../../../components/elements/BoxCode";
import Preloader from "../../../components/elements/Preloader";
import Main from "../../../components/layout/Main";

const code = `
    import { useEffect, useState } from "react";
    import Preloader from "../../../components/elements/Preloader";

    export default function PreloaderExample() {

        const [isLoad, setIsLoad] = useState(false);

        useEffect(() => {
            setTimeout(() => {
                setIsLoad(true)
            }, 5000);
        }, [])
        
        return (
            !isLoad ? <Preloader></Preloader> :
                <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Preloader' code={code}>
                    <Preloader></Preloader>
                </BoxCode>
            </div>
        );
    }
`;

export default function PreloaderExample() {

    const [isLoad, setIsLoad] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsLoad(true)
        }, 5000);
    }, [])
    

    return (
        <Main>
            {!isLoad ? <Preloader></Preloader> :
            <div className="section elementos">
            <h5 className="c-green">Componentes</h5>
            <h2>Elementos</h2>

            <BoxCode title='Preloader' code={code}>
                <Preloader></Preloader>
            </BoxCode>
        </div> }
        </Main>
    );
}