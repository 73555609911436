import React, { useEffect, useRef, useState, Fragment } from "react";

export default function ProgressBar({ color, height, type, isSuscced, isError, time, porcentaje = 100, isAnimation, isPorcentaje, isRound, gap=20 }) {

    porcentaje = porcentaje ?? 100
    isAnimation = isAnimation ?? true
    isPorcentaje = isPorcentaje ?? true
    isRound = isRound ?? true

    const [load, setLoad] = useState(0);

    const progress = useRef(null);

    useEffect(() => {
        if (color != undefined && color != '') {
            if (!color.includes('#') && !color.includes('rgb')) {
                progress.current.style.backgroundColor = null;
                progress.current.classList.remove(progress.current.classList[progress.current.classList.length - 1])
                progress.current.classList.add(color)
            } else {
                progress.current.style.backgroundColor = color;
            }
        }
    }, [color])

    useEffect(() => {
        if (!isAnimation) {
            setLoad(porcentaje);
        } else {
            if (isError != undefined && isError != false) {
                progress.current.style.backgroundColor = '#f00'
            } else {
                if (isSuscced != undefined && isSuscced != false) {
                    setLoad(porcentaje);
                } else {
                    if (load < (porcentaje == 100 ? (porcentaje - 5) : porcentaje)) {
                        loading();  
                    }   
                } 
            }     
        }
    }, [load, isSuscced, porcentaje])
    
    function loading() {
        setTimeout(() => {
            setLoad(load + Math.floor(Math.random() * (5 - 1) + 1));
        }, time ?? porcentaje);
    }

    if (type != undefined) {
        
        switch (type.toUpperCase()) {
            case 'PORCENTAJE':
                return (
                    <div className="progress-container" style={{gap: typeof gap == 'string' ? gap : `${gap}px`}}>
                        {isError != undefined && isError != false ? <p>Error</p> : <></>}
                        <div className="linea-progress" style={{ height: `${height}px` }}>
                            <span className={`back ${isRound ? 'round-10' : ''}`}></span>
                            <span ref={progress} className={`progress ${isRound ? 'round-10' : ''} ${isAnimation ? 'animation' : ''}`} style={{ width: `${load <= porcentaje ? load  : porcentaje }%` }}></span>
                        </div>
                        {isPorcentaje ? <p>{load <= porcentaje ? load  : porcentaje }%</p> : ''}
                    </div>
                );
        
            default:
    
        }

    } else {
        return (
            <div className="progress-container" style={{gap: typeof gap == 'string' ? gap : `${gap}px`}}>
                <div className="linea-progress" style={{ height: `${height}px` }}>
                    <span className={`back ${isRound ? 'round-10' : ''}`}></span>
                    <span ref={progress} className={`progress ${isRound ? 'round-10' : ''} ${isAnimation ? 'animate animation' : 'no-animation'}`} style={{ width: `${porcentaje}%` }}></span>
                </div>
            </div>
        );
    }

}