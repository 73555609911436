import React from "react";
import {useState, useEffect, useRef} from "react";

// Components
import Header from "../Header";
import Sidebar from "../Sidebar";

export default function Main({children}) {
    //HEIGHT CALCS
    const [height, setHeight] = useState(0);

    useEffect(() => {
        setHeight(window.innerHeight);
        //console.log(window.innerHeight);
    });

    return (
        <>
            <Header />

            <main className="main w100 scroll-y" style={{minHeight: `${height}px`}}>
                <div className="wrapper flex row g40">
                    <Sidebar />

                    <div className="content p40">{children}</div>
                </div>
            </main>
        </>
    );
}
