
import React from "react";

export default function List({children, type, className}) {
    switch (type) {
        case "ol":
            return <ol className={`${className !== undefined ? className : ""}`}>{children}</ol>;

        case "hierarchy":
            return <ul className={`hierarchy ${className !== undefined ? className : ""}`}>{children}</ul>;

        default:
            return <ul className={`default ${className !== undefined ? className : ""}`}>{children}</ul>;

        // default:
        //     return <ul className={`${className !== undefined ? className : ""}`}>{children}</ul>;
    }
}
