
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import Main from "../../../components/layout/Main";
import BoxCode from "../../../components/elements/BoxCode";

const data = [
  { marca: "Motorola", porcentaje: 30.13, suma: 66448.0 },
  { marca: "Xiaomi", porcentaje: 19.24, suma: 42427.0 },
  { marca: "Huawei", porcentaje: 15.15, suma: 33398.0 },
  { marca: "Samsung", porcentaje: 13.39, suma: 29525.0 },
  { marca: "Huawei", porcentaje: 8.28, suma: 18262.0 },
  { marca: "ZTE", porcentaje: 5.85, suma: 12908.0 },
  { marca: "LG", porcentaje: 4.07, suma: 8979.0 },
  { marca: "TCT", porcentaje: 1.76, suma: 3887.0 },
  { marca: "Apple", porcentaje: 1.1, suma: 2435.0 },
  { marca: "Lanix", porcentaje: 1.02, suma: 2252.0 }
];

const code = `
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const data = [
    { marca: "Motorola", porcentaje: 30.13, suma: 66448.0 },
    { marca: "Xiaomi", porcentaje: 19.24, suma: 42427.0 },
    { marca: "Huawei", porcentaje: 15.15, suma: 33398.0 },
    { marca: "Samsung", porcentaje: 13.39, suma: 29525.0 },
    { marca: "Huawei", porcentaje: 8.28, suma: 18262.0 },
    { marca: "ZTE", porcentaje: 5.85, suma: 12908.0 },
    { marca: "LG", porcentaje: 4.07, suma: 8979.0 },
    { marca: "TCT", porcentaje: 1.76, suma: 3887.0 },
    { marca: "Apple", porcentaje: 1.1, suma: 2435.0 },
    { marca: "Lanix", porcentaje: 1.02, suma: 2252.0 }
];

export default function LineExample() {
    return (
        <ResponsiveContainer width="100%" height={300}>
            <LineChart data={data} margin={{ top: 5, right: 10, left: 0, bottom: 0 }}>
                <CartesianGrid strokeDasharray="3 3" stroke="#999999" />
                <XAxis height={30} dataKey="marca" />
                <YAxis width={35} dataKey="porcentaje" tickFormatter={tick => tick + '%'} domain={[0, 40]} />
                <Tooltip content={renderTooltip} />
                <Legend verticalAlign="bottom" align="left" height={50} />
                <Line type="monotone" dataKey="porcentaje" stroke="#71d44c" />
            </LineChart>
        </ResponsiveContainer>
    );
}

function renderTooltip({ active, payload, label }) {
    if (active) {
        return (
            <div className="custom-tooltip">
                <p className="label">{label}</p>
                <p className="value"><small></small> {payload[0].value} %</p>
            </div>
        );
    }

    return null;
}
`;

export default function LineExample() {
  return (
    <Main>
      <BoxCode title='Gráfica de lineas' code={code}>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data} margin={{ top: 5, right: 10, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#999999" />
            <XAxis height={30} dataKey="marca" />
            <YAxis width={35} dataKey="porcentaje" tickFormatter={tick => `${tick}%`} domain={[0, 40]} />
            <Tooltip content={renderTooltip} />
            <Legend verticalAlign="bottom" align="left" height={50} />
            <Line type="monotone" dataKey="porcentaje" stroke="#71d44c" />
          </LineChart>
        </ResponsiveContainer>
      </BoxCode>
    </Main>
  );
}

function renderTooltip({ active, payload, label }) {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p className="label">{label}</p>
        <p className="value"><small></small> {payload[0].value} %</p>
      </div>
    );
  }

  return null;
}