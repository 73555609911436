
// Layout
import Main from "../../../components/layout/Main";
import React from "react";
// Component
import BoxCode from "../../../components/elements/BoxCode";
import Item from "../../../components/elements/Item";
import List from "../../../components/elements/List";


export default function Listas() {

    const code1 = `
import List from "../../../components/elements/List";
import Item from "../../../components/elements/Item";

export default function ListaExample() {
    return (
        <>
                        <List>
                                <Item>Lista sin anidado</Item>
                                <Item>Item 2</Item>
                                <Item>Item 3</Item>
                        </List>

                        <List>
                                <Item>
                                        Lista con anidado de 1 nivel
                                        <List>
                                                <Item>Item 1</Item>
                                                <Item>Item 2</Item>
                                                <Item>Item 3</Item>
                                        </List>
                                </Item>
                                <Item>Item 2</Item>
                                <Item>Item 3</Item>
                        </List>

                        <List>
                                <Item>
                                        Lista con anidado de 2 niveles
                                        <List>
                                                <Item>
                                                        Item 1
                                                        <List>
                                                                <Item>
                                                                        Item 1
                                                                        <List>
                                                                                <Item>
                                                                                        Item 1
                                                                                        <List>
                                                                                                <Item>
                                                                                                        Item 1
                                                                                                        <List>
                                                                                                                <Item>Item 1</Item>
                                                                                                                <Item>Item 2</Item>
                                                                                                                <Item>Item 3</Item>
                                                                                                        </List>
                                                                                                </Item>
                                                                                                <Item>Item 2</Item>
                                                                                                <Item>Item 3</Item>
                                                                                        </List>
                                                                                </Item>
                                                                                <Item>Item 2</Item>
                                                                                <Item>Item 3</Item>
                                                                        </List>
                                                                </Item>
                                                                <Item>Item 2</Item>
                                                                <Item>Item 3</Item>
                                                        </List>
                                                </Item>
                                                <Item>Item 2</Item>
                                                <Item>Item 3</Item>
                                        </List>
                                </Item>
                                <Item>Item 2</Item>
                                <Item>Item 3</Item>
                        </List>
                </>
    );
}`;

    const code2 = `
import List from "../../../components/elements/List";
import Item from "../../../components/elements/Item";

export default function ListaExample() {
    return (
        <>
                        <List type="ol">
                                <Item>Lista sin anidado</Item>
                                <Item>Item</Item>
                                <Item>Item</Item>
                        </List>

                        <List type="ol">
                                <Item>
                                        Lista con anidado de 1 nivel
                                        <List type="ol">
                                                <Item>Item</Item>
                                                <Item>Item</Item>
                                                <Item>Item</Item>
                                        </List>
                                </Item>
                                <Item>Item</Item>
                                <Item>Item</Item>
                        </List>

                        <List type="ol">
                                <Item>
                                        Lista con anidado de 2 niveles
                                        <List type="ol">
                                                <Item>
                                                        Item
                                                        <List type="ol">
                                                                <Item>Item</Item>
                                                                <Item>Item</Item>
                                                                <Item>Item</Item>
                                                        </List>
                                                </Item>
                                                <Item>Item</Item>
                                                <Item>Item</Item>
                                        </List>
                                </Item>
                                <Item>Item</Item>
                                <Item>Item</Item>
                        </List>
                </>
    );
}`;

    const code3 = `
import List from "../../../components/elements/List";
import Item from "../../../components/elements/Item";
                    
export default function ListaExample() {
    return (
        <List className="stoplight">
                        <Item className="ok">Ok</Item>
                        <Item className="warning">Warning</Item>
                        <Item className="error">Error</Item>
                </List>
    );
}`;

    const code4 = `
import List from "../../../components/elements/List";
import Item from "../../../components/elements/Item";
                    
export default function ListaExample() {
    return (
        <List type="hierarchy">
                        <Item>
                                Lista con anidado de 2 niveles
                                <List type="hierarchy">
                                        <Item>
                                                Item 1
                                                <List type="hierarchy">
                                                        <Item>Item 1</Item>
                                                        <Item>Item 2</Item>
                                                        <Item>Item 3</Item>
                                                </List>
                                        </Item>
                                        <Item>Item 2</Item>
                                        <Item>Item 3</Item>
                                </List>
                        </Item>
                        <Item>Item 2</Item>
                        <Item>Item 3</Item>
                </List>
    );
}`;

    const code5 = `
import List from "../../../components/elements/List";
import Item from "../../../components/elements/Item";
                    
export default function ListaExample() {
    return (
        <>
                        <List>
                                <Item>Item 1</Item>
                                <Item className="parent">
                                        Item 2
                                        <List type="ol">
                                                <Item>Item 2.1</Item>
                                                <Item>Item 2.2</Item>
                                                <Item>Item 2.3</Item>
                                        </List>
                                </Item>
                                <Item className="parent">
                                        Item 3
                                        <List type="ol">
                                                <Item>Item 3.1</Item>
                                                <Item>
                                                        Item 3.2
                                                        <List className="stoplight">
                                                                <Item className="ok">Item 3.2.1</Item>
                                                                <Item className="warning">Item 3.2.2</Item>
                                                                <Item className="error">Item 3.2.3</Item>
                                                        </List>
                                                </Item>
                                                <Item>Item 3.3</Item>
                                        </List>
                                </Item>
                        </List>

                        <List>
                                <Item>Item 1</Item>
                                <Item className="parent">
                                        Item 2
                                        <List type="">
                                                <Item>Item 2.1</Item>
                                                <Item>Item 2.2</Item>
                                                <Item>Item 2.3</Item>
                                        </List>
                                </Item>
                                <Item className="parent">
                                        Item 3
                                        <List type="">
                                                <Item>Item 3.1</Item>
                                                <Item>
                                                        Item 3.2
                                                        <List className="">
                                                                <Item className="ok">Item 3.2.1</Item>
                                                                <Item className="warning">Item 3.2.2</Item>
                                                                <Item className="error">Item 3.2.3</Item>
                                                        </List>
                                                </Item>
                                                <Item>Item 3.3</Item>
                                        </List>
                                </Item>
                        </List>
                </>
    );
}`;

    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Lista' classBox='gray6' code={code1}>
                    <List>
                        <Item>Lista sin anidado</Item>
                        <Item>Item 2</Item>
                        <Item>Item 3</Item>
                    </List>

                    <List>
                        <Item>
                            Lista con anidado de 1 nivel
                            <List>
                                <Item>Item 1</Item>
                                <Item>Item 2</Item>
                                <Item>Item 3</Item>
                            </List>
                        </Item>
                        <Item>Item 2</Item>
                        <Item>Item 3</Item>
                    </List>

                    <List>
                        <Item>
                            Lista con anidado de 2 niveles
                            <List>
                                <Item>
                                    Item 1
                                    <List>
                                        <Item>
                                            Item 1
                                            <List>
                                                <Item>
                                                    Item 1
                                                    <List>
                                                        <Item>
                                                            Item 1
                                                            <List>
                                                                <Item>Item 1</Item>
                                                                <Item>Item 2</Item>
                                                                <Item>Item 3</Item>
                                                            </List>
                                                        </Item>
                                                        <Item>Item 2</Item>
                                                        <Item>Item 3</Item>
                                                    </List>
                                                </Item>
                                                <Item>Item 2</Item>
                                                <Item>Item 3</Item>
                                            </List>
                                        </Item>
                                        <Item>Item 2</Item>
                                        <Item>Item 3</Item>
                                    </List>
                                </Item>
                                <Item>Item 2</Item>
                                <Item>Item 3</Item>
                            </List>
                        </Item>
                        <Item>Item 2</Item>
                        <Item>Item 3</Item>
                    </List>
                </BoxCode>


                <BoxCode title='Lista numerada' classBox='gray6' code={code2}>
                    <List type="ol">
                        <Item>Lista sin anidado</Item>
                        <Item>Item</Item>
                        <Item>Item</Item>
                    </List>

                    <List type="ol">
                        <Item>
                            Lista con anidado de 1 nivel
                            <List type="ol">
                                <Item>Item</Item>
                                <Item>Item</Item>
                                <Item>Item</Item>
                            </List>
                        </Item>
                        <Item>Item</Item>
                        <Item>Item</Item>
                    </List>

                    <List type="ol">
                        <Item>
                            Lista con anidado de 2 niveles
                            <List type="ol">
                                <Item>
                                    Item
                                    <List type="ol">
                                        <Item>Item</Item>
                                        <Item>Item</Item>
                                        <Item>Item</Item>
                                    </List>
                                </Item>
                                <Item>Item</Item>
                                <Item>Item</Item>
                            </List>
                        </Item>
                        <Item>Item</Item>
                        <Item>Item</Item>
                    </List>
                </BoxCode>


                <BoxCode title='Lista semaforo' classBox='gray6' code={code3}>
                    <List className="stoplight">
                        <Item className="ok">Ok</Item>
                        <Item className="warning">Warning</Item>
                        <Item className="error">Error</Item>
                    </List>
                </BoxCode>


                <BoxCode title='Lista Jerárquica' classBox='gray6' code={code4}>
                    <List type="hierarchy">
                        <Item>
                            Lista con anidado de 2 niveles
                            <List type="hierarchy">
                                <Item>
                                    Item 1
                                    <List type="hierarchy">
                                        <Item>Item 1</Item>
                                        <Item>Item 2</Item>
                                        <Item>Item 3</Item>
                                    </List>
                                </Item>
                                <Item>Item 2</Item>
                                <Item>Item 3</Item>
                            </List>
                        </Item>
                        <Item>Item 2</Item>
                        <Item>Item 3</Item>
                    </List>
                </BoxCode>


                <BoxCode title='Lista anidada' classBox='gray6' code={code5}>
                    <List>
                        <Item>Item 1</Item>
                        <Item className="parent">
                            Item 2
                            <List type="ol">
                                <Item>Item 2.1</Item>
                                <Item>Item 2.2</Item>
                                <Item>Item 2.3</Item>
                            </List>
                        </Item>
                        <Item className="parent">
                            Item 3
                            <List type="ol">
                                <Item>Item 3.1</Item>
                                <Item>
                                    Item 3.2
                                    <List className="stoplight">
                                        <Item className="ok">Item 3.2.1</Item>
                                        <Item className="warning">Item 3.2.2</Item>
                                        <Item className="error">Item 3.2.3</Item>
                                    </List>
                                </Item>
                                <Item>Item 3.3</Item>
                            </List>
                        </Item>
                    </List>

                    <List>
                        <Item>Item 1</Item>
                        <Item className="parent">
                            Item 2
                            <List type="">
                                <Item>Item 2.1</Item>
                                <Item>Item 2.2</Item>
                                <Item>Item 2.3</Item>
                            </List>
                        </Item>
                        <Item className="parent">
                            Item 3
                            <List type="">
                                <Item>Item 3.1</Item>
                                <Item>
                                    Item 3.2
                                    <List className="">
                                        <Item className="ok">Item 3.2.1</Item>
                                        <Item className="warning">Item 3.2.2</Item>
                                        <Item className="error">Item 3.2.3</Item>
                                    </List>
                                </Item>
                                <Item>Item 3.3</Item>
                            </List>
                        </Item>
                    </List>
                </BoxCode>
            </div>
        </Main>
    );
}
