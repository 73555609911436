import React, { Fragment } from "react";
import { useCreateElementImage } from "../../hooks";

export default function BoxLogin({ children, logo, alt, className, title }) {
    
    return (
        <div className="box-login">
            {useCreateElementImage(logo)}

            <h2 className="title">{title}</h2>

            <div className="box-login-content">
                {children}
            </div>
        </div>
    );
}