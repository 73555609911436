import React from "react";
// Layout
import Main from "../../../components/layout/Main";

// Component
import BoxCode from "../../../components/elements/BoxCode";
import Switch from "../../../components/elements/Switch";
import RadioLabel from "../../../components/elements/RadioLabel";


export default function SwitchExample() {

    const code1 = `
import Switch from "../../../components/elements/Switch";

export default function SwitchExample() {
    return (
        <>
                        <Switch />
                        <Switch className='blue'/>
                </>
    );
}`;

    const code2 = `
import Switch from "../../../components/elements/Switch";
import RadioLabel from './../../../components/elements/RadioLabel';

export default function SwitchExample() {
    return (
        <Switch type='code'/>
    );
}`;

    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Switch' code={code1}>
                    <Switch />
                    <Switch className='blue'/>
                </BoxCode>


                <BoxCode title='Switch Code' code={code2}>
                    <Switch type='code'/>
                </BoxCode>

                <BoxCode title='Switch Checked'>
                    <Switch isChecked={true}/>
                    <Switch className='blue' isChecked={true}/>

                    <Switch type='code' isChecked={true}/>
                </BoxCode>

                <BoxCode title='Switch Color Checked'>
                    <Switch colorFalse='bg-green' colorTrue='#ffff00'/>
                </BoxCode>

                <RadioLabel label="test" oso='si' line={false}/>
                <RadioLabel/>
            </div>
        </Main>
    );
}