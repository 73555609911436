import React from "react";

// Layout
import Main from "../../../components/layout/Main";

// Component
import BoxCode from "../../../components/elements/BoxCode";
import Modal from "../../../components/elements/Modal";
import Button from "../../../components/elements/Button";

// React
import { useState } from "react";
import Dropdown from "../../../components/elements/Dropdown";

const code = `
import Modal from "../../../components/elements/Modal";
import Button from "../../../components/elements/Button";

export default function ModalExample() {
    return (
        <>
                    <Button onClick={() => setIsOpen(!isOpen)}>Open Modal</Button>
                    <Modal open={isOpen} onClose={() => setIsOpen(false)} size='600'>
                            <h2>Modal</h2>
                            <p>Modal de prueba</p>
                    </Modal>
            </>
    );
}`;

const Items = [{ 
    value: "United States",
    id: 1 
},
{ 
    value: "Canada",
    id: 2 
},
{ 
    value: "México",
    id: 3 
},
{ 
    value: "Japan",
    id: 4 
},
{ 
    value: "China",
    id: 5 
},
{ 
    value: "India",
    id: 6 
},
{ 
    value: "Brazil",
    id: 7 
},
{ 
    value: "Argentina",
    id: 8 
},
{ 
    value: "Chile",
    id: 9 
},
{ 
    value: "Japan",
    id: 4 
},
{ 
    value: "China",
    id: 5 
},
{ 
    value: "India",
    id: 6 
},
{ 
    value: "Brazil",
    id: 7 
},
{ 
    value: "Argentina",
    id: 8 
},
{ 
    value: "Chile",
    id: 9 
},
{ 
    value: "Japan",
    id: 4 
},
{ 
    value: "China",
    id: 5 
},
{ 
    value: "India",
    id: 6 
},
{ 
    value: "Brazil",
    id: 7 
},
{ 
    value: "Argentina",
    id: 8 
},
{ 
    value: "Chile",
    id: 9 
},
{ 
    value: "Japan",
    id: 4 
},
{ 
    value: "China",
    id: 5 
},
{ 
    value: "India",
    id: 6 
},
{ 
    value: "Brazil",
    id: 7 
},
{ 
    value: "Argentina",
    id: 8 
},
{ 
    value: "Chile",
    id: 9 
},
{ 
    value: "Peru",
    id: 10 
}]

export default function ModalExample() {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Modal' classBox='gray6' code={code}>
                    <Button onClick={() => setIsOpen(!isOpen)}>Open Modal</Button>
                    <Modal titulo={'prueba'} open={isOpen} onClose={() => setIsOpen(false)} size='600'>
                        <h2>Modal</h2>
                        <p>Modal de prueba</p>

                        <Dropdown titulo='País' placeholder="Selecciona un país" items={Items} />
                        <Dropdown titulo='País' placeholder="Selecciona un país" items={Items} />
                        <Dropdown titulo='País' placeholder="Selecciona un país" items={Items} />
                        <Dropdown titulo='País' placeholder="Selecciona un país" items={Items} />
                    </Modal>
                </BoxCode>
            </div>
        </Main>
    );
}