import React from "react";

// Router
import {Link, useLocation} from "react-router-dom";

export default function Sidebar() {
    const {pathname} = useLocation();

    return (
        <div className="sidebar fixed">
            <div className="wrapper p40 flex col scroll-y">
                <h5>Fundaciones</h5>
                <ul className="ls-no flex col">
                    <li className={`p5 ${pathname.includes("colores") ? "selected" : ""}`}>
                        <Link to="/fundaciones/colores">Colores</Link>
                    </li>
                    <li className={`p5 ${pathname.includes("tipografia") ? "selected" : ""}`}>
                        <Link to="/fundaciones/tipografia">Tipografía</Link>
                    </li>
                </ul>
                <h5>Componentes</h5>
                <ul className="ls-no flex col">
                    <li className={`p5 ${pathname.includes("botones") ? "selected" : ""}`}>
                        <Link to="/componentes/botones">Botones</Link>
                    </li>
                    <li className={`p5 ${pathname.includes("cards") ? "selected" : ""}`}>
                        <Link to="/componentes/cards">Cards</Link>
                    </li>
                    <li className={`p5 ${pathname.includes("navbar") ? "selected" : ""}`}>
                        <Link to="/componentes/navbar">Navbar</Link>
                    </li>

                    <li className={`p5 ${pathname.includes("sidebar") ? "selected" : ""}`}>
                        <Link to="/componentes/sidebar">Sidebar</Link>
                    </li>
                    <li className={`p5 ${pathname.includes("dropdown") ? "selected" : ""}`}>
                        <Link to="/componentes/dropdown">Dropdown</Link>
                    </li>

                    <li className={ `p5 ${ pathname.includes('listas') ? 'selected' : '' }` }>
                        <Link to='/componentes/listas'>Listas</Link>
                    </li>

                    <li className={ `p5 ${ pathname.includes('breadcrumb') ? 'selected' : '' }` }>
                        <Link to='/componentes/breadcrumb'>Breadcrumb</Link>
                    </li>

                    <li className={ `p5 ${ pathname.includes('tablas') ? 'selected' : '' }` }>
                        <Link to='/componentes/tablas'>Tablas</Link>
                    </li>

                    <li className={ `p5 ${ pathname.includes('tableV2') ? 'selected' : '' }` }>
                        <Link to='/componentes/tableV2'>Tablas V2</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('tags') ? 'selected' : '' }` }>
                        <Link to='/componentes/tags'>Tags</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('tabs') ? 'selected' : '' }` }>
                        <Link to='/componentes/tabs'>Tabs</Link>
                    </li>

                    <li className={ `p5 ${ pathname.includes('listados') ? 'selected' : '' }` }>
                        <Link to='/componentes/listados'>Listados</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('inputs') ? 'selected' : '' }` }>
                        <Link to='/componentes/inputs'>Inputs</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('progress-bar') ? 'selected' : '' }` }>
                        <Link to='/componentes/progress-bar'>Progress bar</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('switch') ? 'selected' : '' }` }>
                        <Link to='/componentes/switch'>Switch</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('modal') ? 'selected' : '' }` }>
                        <Link to="/componentes/modal">Modal</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('uptime') ? 'selected' : '' }` }>
                        <Link to='/componentes/uptime'>Uptime</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('tooltip') ? 'selected' : '' }` }>
                        <Link to='/componentes/tooltip'>Tooltip</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('grid-status') ? 'selected' : '' }` }>
                        <Link to='/componentes/grid-status'>Grid Status</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('box-login') ? 'selected' : '' }` }>
                        <Link to='/componentes/box-login'>Box Login</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('preloader') ? 'selected' : '' }` }>
                        <Link to='/componentes/preloader'>Preloader</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('static-sidebar') ? 'selected' : '' }` }>
                        <Link to='/componentes/static-sidebar'>Static Sidebar</Link>
                    </li>
                </ul>
                <h5>Campañas</h5>
                <ul className="ls-no flex col">
                    <li className={ `p5 ${ pathname.includes('/campanas/opcion-simple') ? 'selected' : '' }` }>
                        <Link to='/campanas/opcion-simple'>Opcion Simple</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('/campanas/opcion-multiple') ? 'selected' : '' }` }>
                        <Link to='/campanas/opcion-multiple'>Opcion Multiple</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('drag-and-drop') ? 'selected' : '' }` }>
                        <Link to='/campanas/drag-and-drop'>Drag and Drop</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('puntuacion') ? 'selected' : '' }` }>
                        <Link to='/campanas/puntuacion'>Puntuación</Link>
                    </li>
                </ul>
                <h5>Gráfica</h5>
                <ul className="ls-no flex col">
                    <li className={ `p5 ${ pathname.includes('/graficas/line') ? 'selected' : '' }` }>
                        <Link to='/graficas/line'>Gráfica de línea</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('/graficas/bar') ? 'selected' : '' }` }>
                        <Link to='/graficas/bar'>Gráfica de Barra</Link>
                    </li>
                    <li className={ `p5 ${ pathname.includes('/graficas/area') ? 'selected' : '' }` }>
                        <Link to='/graficas/area'>Gráfica de Área</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}
