import React from "react";
// React
import { Routes, Route, Navigate } from 'react-router-dom';

// Routes
import FundacionesRoutes from '../pages/fundaciones/routes/FundacionesRoutes';
import CampanasRoutes from "../pages/campanas/routes/CampanasRoutes";
import ComponentesRoutes from '../pages/componentes/routes/ComponentesRoutes';
import GraficasRoutes from "../pages/graficas/routes/GraficasRoutes";

export default function AppRouter() {

    return (
        <Routes>
            <Route path='fundaciones/*' element={<FundacionesRoutes /> } />
            <Route path='componentes/*' element={<ComponentesRoutes /> } />
            <Route path='campanas/*' element={<CampanasRoutes /> } />
            <Route path='graficas/*' element={<GraficasRoutes /> } />

            <Route path='/' element={<Navigate to={'fundaciones'} />} />
        </Routes>
    );
}