
// Layout
import Main from "../../../components/layout/Main";
import React from "react";
// Component
import Dropdown from "../../../components/elements/Dropdown";
import BoxCode from "../../../components/elements/BoxCode";

const Items = [{ 
    value: "United States",
    id: 1 
},
{ 
    value: "Canada",
    id: 2 
},
{ 
    value: "México",
    id: 3 
},
{ 
    value: "Japan",
    id: 4 
},
{ 
    value: "China",
    id: 5 
},
{ 
    value: "India",
    id: 6 
},
{ 
    value: "Brazil",
    id: 7 ,
    checked: true
},
{ 
    value: "Argentina",
    id: 8 
},
{ 
    value: "Chile",
    id: 9 
},
{ 
    value: "Peru",
    id: 10 ,
    checked: true
}]


export default function DropdownExample() {
    const [selected, setSelected] = React.useState([]);

    React.useEffect(() => {
        //selected.filter(item => item.checked === true).map(item => console.log(item))
    }, [selected]);
    

    const code1 = `
import Dropdown from "../../../components/elements/Dropdown";

const Items = [{ 
    value: "United States",
    id: 1 
},
{ 
    value: "Canada",
    id: 2 
},
{ 
    value: "México",
    id: 3 
},
{ 
    value: "Japan",
    id: 4 
},
{ 
    value: "China",
    id: 5 
},
{ 
    value: "India",
    id: 6 
},
{ 
    value: "Brazil",
    id: 7 
},
{ 
    value: "Argentina",
    id: 8 
},
{ 
    value: "Chile",
    id: 9 
},
{ 
    value: "Peru",
    id: 10 
}]

export default function DropdownExample() {
    return (
        <Dropdown placeholder="Selecciona un país" items={Items} />
    );
}`;

    const code2 = `
import Dropdown from "../../../components/elements/Dropdown";

const Items = [{ 
    value: "United States",
    id: 1 
},
{ 
    value: "Canada",
    id: 2 
},
{ 
    value: "México",
    id: 3 
},
{ 
    value: "Japan",
    id: 4 
},
{ 
    value: "China",
    id: 5 
},
{ 
    value: "India",
    id: 6 
},
{ 
    value: "Brazil",
    id: 7 
},
{ 
    value: "Argentina",
    id: 8 
},
{ 
    value: "Chile",
    id: 9 
},
{ 
    value: "Peru",
    id: 10 
}]

export default function DropdownExample() {
    return (
        <Dropdown placeholder="Selecciona un país" items={Items} search={true} />
    );
}`;

const code3 = `
import Dropdown from "../../../components/elements/Dropdown";

const Items = [{ 
    value: "United States",
    id: 1 
},
{ 
    value: "Canada",
    id: 2 
},
{ 
    value: "México",
    id: 3 
},
{ 
    value: "Japan",
    id: 4 
},
{ 
    value: "China",
    id: 5 
},
{ 
    value: "India",
    id: 6 
},
{ 
    value: "Brazil",
    id: 7 
},
{ 
    value: "Argentina",
    id: 8 
},
{ 
    value: "Chile",
    id: 9 
},
{ 
    value: "Peru",
    id: 10 
}]

export default function DropdownExample() {
    return (
        <Dropdown placeholder="Selecciona los paises" items={Items} isList={true} />
    );
}`;

const code4 = `
import Dropdown from "../../../components/elements/Dropdown";

const Items = [{ 
    value: "United States",
    id: 1 
},
{ 
    value: "Canada",
    id: 2 
},
{ 
    value: "México",
    id: 3 
},
{ 
    value: "Japan",
    id: 4 
},
{ 
    value: "China",
    id: 5 
},
{ 
    value: "India",
    id: 6 
},
{ 
    value: "Brazil",
    id: 7 
},
{ 
    value: "Argentina",
    id: 8 
},
{ 
    value: "Chile",
    id: 9 
},
{ 
    value: "Peru",
    id: 10 
}]

export default function DropdownExample() {
    return (
        <Dropdown placeholder="Selecciona los paises" items={Items} isList={true} search={true} />
    );
}`;


const code5 = `
import Dropdown from "../../../components/elements/Dropdown";

const Items = [{ 
    value: "United States",
    id: 1 
},
{ 
    value: "Canada",
    id: 2 
},
{ 
    value: "México",
    id: 3 
},
{ 
    value: "Japan",
    id: 4 
},
{ 
    value: "China",
    id: 5 
},
{ 
    value: "India",
    id: 6 
},
{ 
    value: "Brazil",
    id: 7 
},
{ 
    value: "Argentina",
    id: 8 
},
{ 
    value: "Chile",
    id: 9 
},
{ 
    value: "Peru",
    id: 10 
}]

export default function DropdownExample() {
    return (
        <Dropdown placeholder="Selecciona los paises" items={Items} isList={true} search={true} showSelected={true} />
    );
}`;
    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Dropdown' classBox='gray6' code={code1}>
                    <Dropdown titulo='País' placeholder="Selecciona un país" items={Items}  id={5} />
                </BoxCode>

                <BoxCode title='Dropdown Add' classBox='gray6' code={code1}>
                    <Dropdown titulo='País' placeholder="Selecciona un país" items={Items} create={true} id="5"/>
                </BoxCode>

                <BoxCode title='Dropdown Search' classBox='gray6' code={code2}>
                    <Dropdown  placeholder="Selecciona un país" items={Items} search={true} />
                </BoxCode>
                <BoxCode title='Dropdown List' classBox='gray6' code={code3}>
                    <Dropdown   placeholder="Selecciona los paises" items={Items} isList={true} onSelectOption={setSelected} />
                </BoxCode>

                <BoxCode title='Dropdown List + Search' classBox='gray6' code={code4}>
                    <Dropdown placeholder="Selecciona los paises" items={Items} isList={true} search={true} onSelectOption={setSelected} />
                </BoxCode>
                
                <BoxCode title='Dropdown List + Search + showSelected' classBox='gray6' code={code5}>
                    <Dropdown titulo='Paises' placeholder="Selecciona los paises" items={Items} isList={true} search={true} onSelectOption={setSelected} showSelected={true} />
                </BoxCode>

            </div>
        </Main>
    );
}