import { useEffect, useRef, useState } from "react";

import Main from "../../../components/layout/Main";
import Col from "../../../components/elements/Table/Col";
import Row from "../../../components/elements/Table/Row";
import Table from "../../../components/elements/Table/Table";

export default function ExampleTableV2() {

  const [result, setResult] = useState([]);

  useEffect(() => {
    setResult([
      { ID: 1, NOMBRE: 'Nombre de prueba', APELLIDO: 'Apellido de prueba', EMAIL: 'Email de prueba', TELEFONO: 'Telefono de prueba', ESTADO: 'Estado de prueba', EDAD: 'Edad de prueba', PUESTO: 'Direccion de prueba', FECHA: 'Fecha de prueba' },
      { ID: 2, NOMBRE: 'Nombre de prueba', APELLIDO: 'Apellido de prueba', EMAIL: 'Email de prueba', TELEFONO: 'Telefono de prueba', ESTADO: 'Estado de prueba', EDAD: 'Edad de prueba', PUESTO: 'Direccion de prueba', FECHA: 'Fecha de prueba' }
    ]);
  }, [])

  return (
    <Main>
      <div className="section elementos">
        <h5 className="c-green">Componentes</h5>
        <h2>Elementos</h2>

        {/* <div className="responsive-table" ref={content}>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Email</th>
                <th>Telefono</th>
                <th>Estado</th>
                <th>Edad</th>
                <th>Direccion</th>
                <th>Fecha</th>
              </tr>
            </thead>
            <tbody>
              {result.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.ID}</td>
                    <td>{item.NOMBRE}</td>
                    <td>{item.APELLIDO}</td>
                    <td>{item.EMAIL}</td>
                    <td>{item.TELEFONO}</td>
                    <td>{item.ESTADO}</td>
                    <td>{item.EDAD}</td>
                    <td>{item.PUESTO}</td>
                    <td>{item.FECHA}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div> */}

        <Table isResponsive={true}>
          <Row type='header'>
            <Col order={true} hide='high'>id</Col>
            <Col hide='high'>Nombre</Col>
            <Col hide='medium'>Apellido</Col>
            <Col hide='low'>email</Col>
            <Col hide='medium'>telefono</Col>
            <Col hide='low'>estado</Col>
            <Col hide='high'>edad</Col>
            <Col hide='medium'>Direccion</Col>
            <Col hide='low'>Fecha</Col>
          </Row>
          {result.map((item, index) => {
            return (
              <Row>
                <Col>{item.ID}</Col>
                <Col max={5}>{item.NOMBRE}</Col>
                <Col>{item.APELLIDO}</Col>
                <Col>{item.EMAIL}</Col>
                <Col>{item.TELEFONO}</Col>
                <Col>{item.ESTADO}</Col>
                <Col>{item.EDAD}</Col>
                <Col>{item.PUESTO}</Col>
                <Col>{item.FECHA}</Col>
              </Row>
            ); 
          })}
        </Table>
{/* 
        <TableV2 isResponsive={true}>
          <RowV2 type='header'>
            <ColV2 order={true} hide='high'>id</ColV2>
            <ColV2 hide='high'>Nombre</ColV2>
            <ColV2 hide='medium'>Apellido</ColV2>
            <ColV2 hide='low'>email</ColV2>
            <ColV2 hide='medium'>telefono</ColV2>
            <ColV2 hide='low'>estado</ColV2>
            <ColV2 hide='high'>edad</ColV2>
            <ColV2 hide='medium'>Direccion</ColV2>
            <ColV2 hide='low'>Fecha</ColV2>
          </RowV2>
          {result.map((item, index) => {
            return (
              <RowV2>
                <ColV2>{item.ID}</ColV2>
                <ColV2 max={5}>{item.NOMBRE}</ColV2>
                <ColV2>{item.APELLIDO}</ColV2>
                <ColV2>{item.EMAIL}</ColV2>
                <ColV2>{item.TELEFONO}</ColV2>
                <ColV2>{item.ESTADO}</ColV2>
                <ColV2>{item.EDAD}</ColV2>
                <ColV2>{item.PUESTO}</ColV2>
                <ColV2>{item.FECHA}</ColV2>
              </RowV2>
            ); 
          })}
        </TableV2> */}
      </div>
    </Main>
  );
}