import React, { Fragment } from "react";
import Input from "./Input";

export default function InputWithLabel({ children, type, placeholder, classInput, classLabel, classContent, onChange, id, value, textLabel, showArrow, autoFocus=false, disabled=false, require=false }) {
    return (
        <>
            <label className={`block ${classLabel}`} htmlFor={id}>{textLabel} {require ? <span className="require">*</span> : '' }</label> 
            <Input children={children} type={type} placeholder={placeholder} className={classInput} onChange={onChange} id={id} value={value} showArrow={showArrow} classContent={classContent} autoFocus={autoFocus} disabled={disabled} />
        </>
    );
}