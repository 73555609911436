import React from "react";
// Layout
import Main from "../../../components/layout/Main";

// Component
import Nav from "../../../components/elements/Nav";
import BoxCode from "../../../components/elements/BoxCode";

// Image
import Logo from '../../../assets/imgs/logo-horizontal.svg';
import { Link, useLocation } from "react-router-dom";

const code1 = `
import Nav from "../../../components/elements/Nav";

export default function NavbarExample() {
    return (
        <Nav />
    );
}`;

const code2 = `
import Nav from "../../../components/elements/Nav";
import { Link, useLocation } from "react-router-dom";

export default function NavbarExample() {

    const data = [{
        text: 'Fundaciones',
        link: <Link href='/fundaciones'></Link>,
        active: '/fundaciones'
    },
    {
        text: 'Componentes',
        link: <Link href='/componentes'></Link>,
        active: '/componentes'
    }]

    const { pathname } = useLocation();

    return (
        <Nav data={data} pathname={pathname} />
    );
}`;

const code3 = `
import Nav from "../../../components/elements/Nav";
import Logo from '../../../assets/imgs/logo-horizontal.svg';

export default function NavbarExample() {
    return (
        <Nav logo={Logo} />
    );
}`;

export default function Navbar() {

    const data = [{
        text: 'Fundaciones',
        link: <Link href='/fundaciones'></Link>,
        active: '/fundaciones'
    },
    {
        text: 'Componentes',
        link: <Link href='/componentes'></Link>,
        active: '/componentes'
    }]

    const data2 = [{
        text: 'Fundaciones',
        link: <Link href='/fundaciones'></Link>,
        active: '/fundaciones'
    },
    {
        text: 'Componentes',
        link: <Link href='/componentes'></Link>,
        active: '/componentes'
    },
    {
        type: 'logout',
        text: 'Adrian Zaid',    
        onClick: () => {},
    }]

    const { pathname } = useLocation();

    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Navbar' classBox='gray6' code={code1}>
                    <Nav navClass='position-relative' />
                </BoxCode>

                <BoxCode title='Navbar + Links' classBox='gray6' code={code2}>
                    <Nav navClass='position-relative' data={data} pathname={pathname} />
                </BoxCode>

                <BoxCode title='Navbar + Links + logout' classBox='gray6' code={code2}>
                    <Nav navClass='position-relative' data={data2} pathname={pathname} />
                </BoxCode>

                <BoxCode title='Navbar + Logo' classBox='gray6' code={code3}>
                    <Nav navClass='position-relative' logo={Logo} />
                </BoxCode>
            </div>
        </Main>
    );
}
