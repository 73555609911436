import React, { useEffect, useRef, useState } from "react";
// Layout
import Main from "../../../components/layout/Main";

import IconBattery from "../../../assets/IconBattery";

// Component
import BoxCode from "../../../components/elements/BoxCode";
import Button from "../../../components/elements/Button";
import Col from "../../../components/elements/Table/Col";
import Row from "../../../components/elements/Table/Row";
import Table from "../../../components/elements/Table/Table";
import ProgressBar from "../../../components/elements/ProgressBar";
import Tag from "../../../components/elements/Tag";
import Switch from "../../../components/elements/Switch";
import GridStatus from "../../../components/elements/GridStatus";
import { Link } from "react-router-dom";

export default function Tablas() {
  const [funcional, setFuncional] = useState(true);
  const [arr, setArr] = useState(["", "", "", "", ""]);
  const [test, setTest] = useState(true);
  const [id, setId] = useState(0);

  const data = [
    [
      {
        value: "1",
        status: "off",
        link: <Link></Link>,
        background: "bg-green",
        color: "#000",
      },
      {
        value: "2",
        status: "on",
        link: <Link></Link>,
        background: "#f00",
        color: "",
      },
      {
        value: "3",
        status: "ok",
        link: <Link></Link>,
        background: "rgba(0,0,0,1)",
        color: "",
      },
    ],
  ];

  const random = () => {
    return Math.floor(Math.random() * (100 - 0 + 1) + 0);
  };

  const [ciudades, setCiudades] = useState([
    {
      id: 1,
      ciudad: "León",
      estadoPorcentaje: (990 / 1000) * 100,
      sistemasTotales: 4,
      sistemasFuncionando: 2,
      subSistemasTotales: 10,
      subSistemasFuncionando: 9,
      lineasTotales: 700,
      lineasFuncionando: 688,
      infraestructuraTotales: 1000,
      infraestructuraFuncionando: 990,
      estatus: {
        errorTipo1: random(),
        errorTipo2: random(),
        errorTipo3: random(),
      },

      tipoFalla: {
        fallaTipo1: random(),
        fallaTipo2: random(),
        fallaTipo3: random(),
        fallaTipo4: random(),
        fallaTipo5: random(),
        fallaTipo6: random(),
      },
    },
    {
      id: 2,
      ciudad: "Monterrey",
      estadoPorcentaje: (990 / 1000) * 100,
      sistemasTotales: 3,
      sistemasFuncionando: 1,
      subSistemasTotales: 15,
      subSistemasFuncionando: 9,
      lineasTotales: 300,
      lineasFuncionando: 290,
      infraestructuraTotales: 1000,
      infraestructuraFuncionando: 999,
      estatus: {
        errorTipo1: random(),
        errorTipo2: random(),
        errorTipo3: random(),
      },

      tipoFalla: {
        fallaTipo1: random(),
        fallaTipo2: random(),
        fallaTipo3: random(),
        fallaTipo4: random(),
        fallaTipo5: random(),
        fallaTipo6: random(),
      },
    },
    {
      id: 3,
      ciudad: "San Luis Potosí",
      estadoPorcentaje: (900 / 1000) * 100,
      sistemasTotales: 6,
      sistemasFuncionando: 2,
      subSistemasTotales: 10,
      subSistemasFuncionando: 7,
      lineasTotales: 240,
      lineasFuncionando: 160,
      infraestructuraTotales: 1000,
      infraestructuraFuncionando: 900,
      estatus: {
        errorTipo1: random(),
        errorTipo2: random(),
        errorTipo3: random(),
      },
      tipoFalla: {
        fallaTipo1: random(),
        fallaTipo2: random(),
        fallaTipo3: random(),
        fallaTipo4: random(),
        fallaTipo5: random(),
        fallaTipo6: random(),
      },
    },
    {
      id: 4,
      ciudad: "CDMX",
      estadoPorcentaje: (770 / 1000) * 100,
      sistemasTotales: 8,
      sistemasFuncionando: 4,
      subSistemasTotales: 10,
      subSistemasFuncionando: 4,
      lineasTotales: 1200,
      lineasFuncionando: 1120,
      infraestructuraTotales: 1000,
      infraestructuraFuncionando: 770,
      estatus: {
        errorTipo1: random(),
        errorTipo2: random(),
        errorTipo3: random(),
      },
      tipoFalla: {
        fallaTipo1: random(),
        fallaTipo2: random(),
        fallaTipo3: random(),
        fallaTipo4: random(),
        fallaTipo5: random(),
        fallaTipo6: random(),
      },
    },
  ]);

  const code = `
import Table from "../../../components/elements/Table/Table";
import Row from "../../../components/elements/Table/Row";
import Col from "../../../components/elements/Table/Col";

export default function TablaExample() {
    return (
        <Table>
                        <Row type='header'>
                                <Col priority='alta' type='number' order={true}>ID</Col>
                                <Col priority='alta' order={true} filter={[true, 'text', 'Buscar Palabra']} max={50}>Default (Texto)</Col>
                                <Col priority='media' type='numero' filter={[true]}>Numero</Col>
                                <Col type='money' size={200}>Money</Col>
                                <Col priority='media' type='icon'>Icon</Col>
                                <Col type='btn'>Botones</Col>
                        </Row>
                        <Row>
                                <Col>5</Col>
                                <Col>Texto de prueba d</Col>
                                <Col>1000</Col>
                                <Col>159753</Col>
                                <Col>
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path fill="currentColor" d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"></path>
                                        </svg>
                                </Col>
                                <Col>
                                        <Button className="primary">Primary</Button>
                                        <Button className="caution">Caution</Button>
                                </Col>
                        </Row>
                        <Row>
                                <Col>4</Col>
                                <Col>Texto de prueba b</Col>
                                <Col>1000</Col>
                                <Col>597531</Col>
                                <Col>
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path fill="currentColor" d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"></path>
                                        </svg>
                                </Col>
                                <Col>
                                        <Button className="primary">Primary</Button>
                                        <Button className="caution">Caution</Button>
                                </Col>
                        </Row>
                        <Row>
                                <Col>3</Col>
                                <Col>Texto de prueba a</Col>
                                <Col>4000</Col>
                                <Col>975315</Col>
                                <Col>
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path fill="currentColor" d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"></path>
                                        </svg>
                                </Col>
                                <Col>
                                        <Button className="primary">Primary</Button>
                                        <Button className="caution">Caution</Button>
                                </Col>
                        </Row>
                        <Row>
                                <Col>2</Col>
                                <Col>Texto de prueba c</Col>
                                <Col>3000</Col>
                                <Col>753159</Col>
                                <Col>
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path fill="currentColor" d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"></path>
                                        </svg>
                                </Col>
                                <Col>
                                        <Button className="primary">Primary</Button>
                                        <Button className="caution">Caution</Button>
                                </Col>
                        </Row>
                        <Row>
                                <Col>1</Col>
                                <Col>Texto de prueba e</Col>
                                <Col>5000</Col>
                                <Col>531597</Col>
                                <Col>
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path fill="currentColor" d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"></path>
                                        </svg>
                                </Col>
                                <Col>
                                        <Button className="primary">Primary</Button>
                                        <Button className="caution">Caution</Button>
                                </Col>
                        </Row>
                </Table>
    );
}`;

  function handleArr() {
    // console.log('llega');
    // setTest(!test);
    let a = [...ciudades];
    a.pop();
    setCiudades(a);
    // setArr(arr.pop());
    // ciudades.pop();
  }

  const cambiarFuncional = () => {
    setFuncional(!funcional);
  };


  return (
    <Main>
      <div className="section elementos">
        <h5 className="c-green">Componentes</h5>
        <h2>Elementos</h2>

        <Table isResponsive={true}>
          <Row type='header'>
            <Col size={30} priority='baja'>Titulo Largo</Col>
            <Col priority='baja'>Titulo Largo</Col>
            <Col priority='media'>Titulo mas Largo</Col>
            <Col priority='media'>Titulo mas Largo</Col>
            <Col priority='alta'>Titulo mucho mas Largo</Col>
            <Col priority='alta'>Titulo mucho mas Largo</Col>
          </Row>
          <Row>
            <Col>Texto Largo</Col>
            <Col>Texto Largo</Col>
            <Col>Texto mas Largo</Col>
            <Col>Texto mas Largo</Col>
            <Col>Titulo mucho mas Largo</Col>
            <Col>Titulo mucho mas Largo</Col>
          </Row>
        </Table>
        {/* 
                <BoxCode title='Tabla' code={code}>
                    <Table>
                        <Row type='header'>
                            <Col priority='alta' type='number' order={true}>ID</Col>
                            <Col priority='alta' order={true} filter={[true, 'text', 'Buscar Palabra']} max={50}>Default (Texto)</Col>
                            <Col priority='media' type='numero' filter={[true]}>Numero</Col>
                            <Col type='money' size={200}>Money</Col>
                            <Col priority='media' type='icon'>Icon</Col>
                            <Col type='btn'>Botones</Col>
                        </Row>
                        <Row>
                            <Col>5</Col>
                            <Col>Texto de prueba d</Col>
                            <Col>1000</Col>
                            <Col>159753</Col>
                            <Col>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor" d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"></path>
                                </svg>
                            </Col>
                            <Col>
                                <Button className="primary">Primary</Button>
                                <Button className="caution">Caution</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>4</Col>
                            <Col>Texto de prueba b</Col>
                            <Col>1000</Col>
                            <Col>597531</Col>
                            <Col>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor" d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"></path>
                                </svg>
                            </Col>
                            <Col>
                                <Button className="primary">Primary</Button>
                                <Button className="caution">Caution</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>3</Col>
                            <Col>Texto de prueba a</Col>
                            <Col>4000</Col>
                            <Col>975315</Col>
                            <Col>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor" d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"></path>
                                </svg>
                            </Col>
                            <Col>
                                <Button className="primary">Primary</Button>
                                <Button className="caution">Caution</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>2</Col>
                            <Col>Texto de prueba c</Col>
                            <Col>3000</Col>
                            <Col>753159</Col>
                            <Col>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor" d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"></path>
                                </svg>
                            </Col>
                            <Col>
                                <Button className="primary">Primary</Button>
                                <Button className="caution">Caution</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>1</Col>
                            <Col>Texto de prueba e</Col>
                            <Col>5000</Col>
                            <Col>531597</Col>
                            <Col>
                                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path fill="currentColor" d="M120 256C120 286.9 94.93 312 64 312C33.07 312 8 286.9 8 256C8 225.1 33.07 200 64 200C94.93 200 120 225.1 120 256zM280 256C280 286.9 254.9 312 224 312C193.1 312 168 286.9 168 256C168 225.1 193.1 200 224 200C254.9 200 280 225.1 280 256zM328 256C328 225.1 353.1 200 384 200C414.9 200 440 225.1 440 256C440 286.9 414.9 312 384 312C353.1 312 328 286.9 328 256z"></path>
                                </svg>
                            </Col>
                            <Col>
                                <Button className="primary">Primary</Button>
                                <Button className="caution">Caution</Button>
                            </Col>
                        </Row>
                    </Table>
                </BoxCode> */}

        <Table gap={50}>
          <Row type="header" key={0}>
            <Col
              priority="alta"
              order={true}
              filter={[true]}
              max={50}
              size={120}
            >
              Ciudad
            </Col>
            <Col priority="media" max={50} filter={[true]}>
              Estado
            </Col>
            <Col priority="media" filter={[true]}>
              Sistema
            </Col>
            <Col priority="media">Subsistema</Col>
            <Col priority="media">Líneas</Col>
            <Col priority="media">Infraestructura</Col>
            <Col not={true} priority="media">
              Estatus
            </Col>
            <Col not={true} priority="media" size={301}>
              Tipo Falla
            </Col>
          </Row>

          {ciudades.map((item, index) => {
            return (
              <Row key={index} id={item.id}>
                <Col className="cursor-pointer">{item.ciudad}</Col>
                <Col>
                  {funcional ? (
                    <ProgressBar
                      type="porcentaje"
                      color={
                        item.estadoPorcentaje >= 95
                          ? "primary"
                          : item.estadoPorcentaje >= 75
                          ? "caution"
                          : "#f44336"
                      }
                      porcentaje={item.estadoPorcentaje}
                      isAnimation={false}
                    />
                  ) : (
                    <ProgressBar
                      type="porcentaje"
                      color={
                        100 - item.estadoPorcentaje >= 95
                          ? "primary"
                          : 100 - item.estadoPorcentaje >= 75
                          ? "caution"
                          : "#f44336"
                      }
                      porcentaje={100 - item.estadoPorcentaje}
                      isAnimation={false}
                    />
                  )}
                </Col>
                <Col>
                  {funcional
                    ? item.sistemasFuncionando
                    : item.sistemasTotales - item.sistemasFuncionando}
                  /{item.sistemasTotales}
                </Col>
                <Col>
                  {funcional
                    ? item.subSistemasFuncionando
                    : item.subSistemasTotales - item.subSistemasFuncionando}
                  /{item.subSistemasTotales}
                </Col>
                <Col>
                  {funcional
                    ? item.lineasFuncionando
                    : item.lineasTotales - item.lineasFuncionando}
                  /{item.lineasTotales}
                </Col>
                <Col>
                  {funcional
                    ? item.infraestructuraFuncionando
                    : item.infraestructuraTotales -
                      item.infraestructuraFuncionando}
                  /{item.infraestructuraTotales}
                </Col>
                <Col>
                  <div className="flex g10">
                    <GridStatus
                      data={[
                        {
                          content: (
                            <div>
                              <span
                                className="gs-icon"
                                style={{ fill: "#71d44c" }}
                              >
                                <IconBattery />
                              </span>
                              {item.estatus.errorTipo1}
                            </div>
                          ),
                        },
                        {
                          content: (
                            <div>
                              <span
                                className="gs-icon"
                                style={{ fill: "#ec7029" }}
                              >
                                <IconBattery />
                              </span>
                              {item.estatus.errorTipo2}
                            </div>
                          ),
                        },
                        {
                          content: (
                            <div>
                              <span
                                className="gs-icon"
                                style={{ fill: "#f44336" }}
                              >
                                <IconBattery />
                              </span>
                              {item.estatus.errorTipo3}
                            </div>
                          ),
                        },
                      ]}
                      id="grid-default"
                      border={false}
                      size={25}
                      padding={5}
                      gap={5}
                      columns={3}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="flex g10">
                    <GridStatus
                      data={[
                        {
                          content: (
                            <div>
                              <span
                                className="gs-icon"
                                style={{ fill: "#2a4fb7" }}
                              >
                                <IconBattery />
                              </span>
                              {item.tipoFalla.fallaTipo1}
                            </div>
                          ),
                        },
                        {
                          content: (
                            <div>
                              <span
                                className="gs-icon"
                                style={{ fill: "#f18f01" }}
                              >
                                <IconBattery />
                              </span>
                              {item.tipoFalla.fallaTipo2}
                            </div>
                          ),
                        },
                        {
                          content: (
                            <div>
                              <span
                                className="gs-icon"
                                style={{ fill: "#c73e1d" }}
                              >
                                <IconBattery />
                              </span>
                              {item.tipoFalla.fallaTipo3}
                            </div>
                          ),
                        },
                        {
                          content: (
                            <div>
                              <span
                                className="gs-icon"
                                style={{ fill: "#3b1f2b" }}
                              >
                                <IconBattery />
                              </span>
                              {item.tipoFalla.fallaTipo4}
                            </div>
                          ),
                        },
                        {
                          content: (
                            <div>
                              <span
                                className="gs-icon"
                                style={{ fill: "#6d98ab" }}
                              >
                                <IconBattery />
                              </span>
                              {item.tipoFalla.fallaTipo5}
                            </div>
                          ),
                        },
                        {
                          content: (
                            <div>
                              <span
                                className="gs-icon"
                                style={{ fill: "#a8b1b8" }}
                              >
                                <IconBattery />
                              </span>
                              {item.tipoFalla.fallaTipo6}
                            </div>
                          ),
                        },
                      ]}
                      id="grid-default"
                      border={false}
                      size={25}
                      padding={5}
                      gap={5}
                      columns={6}
                    />
                  </div>
                </Col>
              </Row>
            );
          })}
        </Table>

        {/* <Switch colorFalse="bg-green" colorTrue="#f44336" onChange={cambiarFuncional} />
                <Button onClick={() => handleArr()}>borrar</Button> */}

        <Button onClick={() => setId(id + 1)}>click en row</Button>
      </div>


      <Prueba />
      {/* <Table>
        <Row type="header">
          {arrTable.map(item => <Col isHidden={3}>Titulo {item}</Col>)}
        </Row>
        <Row>
          {arrTable.map(item => <Col>Texto {item}</Col>)}
        </Row>
      </Table> */}
    </Main>
  );
}

const arrTable = [
  {
    name: 'id',
    isPriority: 0
  },
  {
    name: 'Nombre',
    isPriority: 0
  },
  {
    name: 'Apellido',
    isPriority: 0
  },
  {
    name: 'email',
    isPriority: 1
  },
  {
    name: 'telefono',
    isPriority: 2
  },
  {
    name: 'estado',
    isPriority: 1
  },
  {
    name: 'edad',
    isPriority: 2
  },
  {
    name: 'Direccion',
    isPriority: 1
  },
  {
    name: 'Fecha',
    isPriority: 2
  }
];

const Prueba = () => {

  const content = useRef(null);

  const [dataTable, setDataTable] = useState([]);

  useEffect(() => {
    let arrOut = [];
    arrTable.forEach(item => arrOut.push({...item, classID: item.isPriority == 0 ? `high` : item.isPriority == 1 ? `medium` : `low`}));
    setDataTable(arrOut)
  }, []);

  useEffect(() => {
    new ResizeObserver(([e]) => resizeTable(e.target.clientWidth)).observe(content.current);
  }, [])

  const resizeTable = wdh => {
    let table = content.current.querySelector('table');
    let header = content.current.querySelector('thead tr');
    let body = content.current.querySelectorAll('tbody tr');

    if (wdh < table.clientWidth) {
      let col = header.querySelectorAll('.low:not(.none)');

      if (col.length > 0) {
        [...col].at(-1).classList.add('none');
      } else {
        let col = header.querySelectorAll('.medium:not(.none)');

        if (col.length > 0) {
          [...col].at(-1).classList.add('none');
        }
      }

      body.forEach(item => {
        let col = item.querySelectorAll('.low:not(.none)');

        if (col.length > 0) {
          [...col].at(-1).classList.add('none');
        } else {
          let col = item.querySelectorAll('.medium:not(.none)');

          if (col.length > 0) {
            [...col].at(-1).classList.add('none');
          }
        }
      });
    }

    if (wdh > (table.clientWidth + 5)) {
      let col = header.querySelectorAll('.low.none');

      if (col.length > 0) {
        [...col].at(-1).classList.remove('none');
      } else {
        let col = header.querySelectorAll('.medium.none');

        if (col.length > 0) {
          [...col].at(-1).classList.remove('none');
        }
      }

      body.forEach(item => {
        let col = item.querySelectorAll('.low.none');

        if (col.length > 0) {
          [...col].at(-1).classList.remove('none');
        } else {
          let col = item.querySelectorAll('.medium.none');

          if (col.length > 0) {
            [...col].at(-1).classList.remove('none');
          }
        }
      });
    }
    
    // [...header.querySelectorAll('.low:not(.none)')].at(-1).classList.add('none');
    // header.querySelector('.low:not(.none):last-of-type').classList.add('none');

    // contentTR.forEach(item => {
    //   if (wdh < table.clientWidth) {
    //     console.log('Menos');
    //   } else {
    //     console.log('Mas');
    //   }
      // if (wdh < table.clientWidth) {
      //   let col = item.querySelector('.low:not(.none)');
        
      //   if (col != null) {
      //     col.classList.add('none');
      //   } else {
      //     let col = item.querySelector('.medium:not(.none)');

      //     if (col == null) return
      //       col.classList.add('none');
      //   }
      // } else {
      //   let col = item.querySelector('.low.none');

      //   if (col != null) {
      //     col.classList.remove('none');
      //   } else {
      //     let col = item.querySelector('.medium.none');

      //     if (col == null) return
      //       col.classList.remove('none');
      //   }
      // }

      // if (wdh < table.clientWidth) {
      //   let th = item.querySelectorAll(`th.low:not(.none)`);
      //   let td = item.querySelectorAll(`td.low:not(.none)`);

      //   if (index == 0) {
      //     if (th.length > 0) {
      //       th[th.length - 1].classList.add('none');
      //     } 
      //   } else {
      //     if (td.length > 0) {
      //       td[td.length - 1].classList.add('none');
      //     }
      //   }
          

        // item.querySelector(`th.low:not(.none):last-of-type`)?.classList.add('none');
        // item.querySelector(`td.low:not(.none):last-of-type`)?.classList.add('none'); 
      // }
      // console.log(index);
      // if (wdh < table.clientWidth) {
      //   console.log(item.querySelector(`${index == 0 ? 'th' : 'td'}.low:not(.none)`));
        // item.querySelector(`${index != 0 ? 'td' : 'th'}.low:not(.none):last-of-type`).classList.add('none')
      // } else {
      //   // console.log(item.querySelector(`${index != 0 ? 'td' : 'th'}.low.none`));
      //     // item.querySelector(`${index != 0 ? 'td' : 'th'}.low.none`).classList.add('block')
      // }
      // // console.log(item.querySelector(`${index != 0 ? 'td' : 'th'}.low:not(none):last-of-type`));
      // // console.log(item.querySelectorAll(`${index != 0 ? 'td' : 'th'}.low():last-of-type`));
      // // let child = item.querySelectorAll(`${index != 0 ? 'td' : 'th'}.low`);

      // // child.forEach(item => {
      // //   if
      // //   console.log(item); 
      // //   // console.log(wdh, table.clientWidth); 
      // // });
    // });
  }

  return (
    <div className="responsive-table" ref={content}>
      <table>
        <thead>
          <tr>
            {dataTable.map((item, index) => <th key={index} className={`${item.classID}`}>{item.name}</th>)}
          </tr>
        </thead>
        <tbody>
          <tr>
            {dataTable.map((item, index) => <td key={index} className={`${item.classID}`}>{item.name}</td>)}
          </tr>
        </tbody>
      </table>
    </div>
  );
}