import BoxCode from "../../../components/elements/BoxCode";
import Main from "../../../components/layout/Main";
import POLITICO1 from "../../../assets/imgs/1.png";
import POLITICO2 from "../../../assets/imgs/2.png";
import POLITICO3 from "../../../assets/imgs/3.png";

export default function DnDExample() {
  return (
    <Main>
      <div className="section elementos">
        <h5 className="c-green">Campañas</h5>
        <h2>Encuestas</h2>

          {/* OPCION SIMPLE */}
          <BoxCode title='Drag and Drop Simple'>
            <div className="body">
              <form className="main-campanas" action="" method="POST" id="form">
  
                {/* Middle */}
                <div className="content-middle height-header-subheader">
                  <div className="middle">
                    <div className="cuestionario">
                      <div className="content-title">
                        <h2 className="title">Ayúdanos contestando las siguientes preguntas</h2>
                        <span className="question-state">Pregunta <span>1</span> de <span>10</span></span>
                      </div>
                      <div className="content-question">
                        <label className="title fs15">Ordena a los personajes en base a tu opinión arrastrándolos a la caja correspondiente.</label>

                        <div class="flex just-sb dnd">
                          <div class="dnd-content">
                            <div class="dnd-content-item" draggable="true">
                              <div class="dnd-item">
                                <img src={POLITICO1} alt="POLITICO" />
                                <span>Ricardo Sheffield</span>
                              </div>
                            </div>
                            <div class="dnd-content-item" draggable="true">
                              <div class="dnd-item">
                                <img src={POLITICO2} alt="POLITICO" />
                                <span>Alejandra Gutiérrez</span>
                              </div>
                            </div>
                            <div class="dnd-content-item" draggable="true">
                              <div class="dnd-item">
                              <img src={POLITICO3} alt="POLITICO" />
                                <span>Libia García</span>
                              </div>
                            </div>
                          </div>

                          <div class="dnd-content">
                            <div class="dnd-content-choice">
                              Buena
                            </div>
                            <div class="dnd-content-choice">
                              <div class="dnd-item">
                                <img src={POLITICO1} alt="POLITICO" />
                                <span>Ricardo Sheffield</span>
                              </div>
                            </div>
                            <div class="dnd-content-choice">
                              No lo conozco
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </BoxCode>

          {/* OPCION SIMPLE */}
          <BoxCode title='Drag and Drop Multiple'>
            <div className="body">
              <form className="main-campanas" action="" method="POST" id="form">
  
                {/* Middle */}
                <div className="content-middle height-header-subheader">
                  <div className="middle">
                    <div className="cuestionario">
                      <div className="content-title">
                        <h2 className="title">Ayúdanos contestando las siguientes preguntas</h2>
                        <span className="question-state">Pregunta <span>1</span> de <span>10</span></span>
                      </div>
                      <div className="content-question">
                        <label className="title fs15">Ordena a los personajes en base a tu opinión arrastrándolos a la caja correspondiente.</label>

                        <div class="flex just-sb dnd">
                          <div class="dnd-content">
                            <div class="dnd-content-item" draggable="true">
                              <div class="dnd-item">
                                <img src={POLITICO1} alt="POLITICO" />
                                <span>Ricardo Sheffield</span>
                              </div>
                            </div>
                            <div class="dnd-content-item" draggable="true">
                              <div class="dnd-item">
                                <img src={POLITICO2} alt="POLITICO" />
                                <span>Ricardo Monreal</span>
                              </div>
                            </div>
                            <div class="dnd-content-item" draggable="true">
                              <div class="dnd-item">
                              <img src={POLITICO3} alt="POLITICO" />
                                <span>Libia García</span>
                              </div>
                            </div>
                          </div>

                          <div class="dnd-content">
                            <div class="dnd-content-choice">
                              Buena
                            </div>
                            <div class="dnd-content-choice">
                              <img src={POLITICO1} alt="POLITICO" />
                              <img src={POLITICO2} alt="POLITICO" />
                            </div>
                            <div class="dnd-content-choice">
                              No lo conozco
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </BoxCode>
      </div>
    </Main>
  )
}