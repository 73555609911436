import BoxCode from "../../../components/elements/BoxCode";
import Main from "../../../components/layout/Main";
import POLITICO1 from "../../../assets/imgs/1.png";
import POLITICO2 from "../../../assets/imgs/2.png";
import POLITICO3 from "../../../assets/imgs/3.png";

export default function DnDExample() {
  return (
    <Main>
      <div className="section elementos">
        <h5 className="c-green">Campañas</h5>
        <h2>Encuestas</h2>

        <BoxCode title='Puntuación'>
          <div className="body">
            <form className="main-campanas" action="" method="POST" id="form">

              {/* Middle */}
              <div class="content-middle height-header-subheader">
                <div class="middle">
                  <div class="cuestionario">
                    <div class="content-title">
                      <h2 class="title">Ayúdanos contestando las siguientes preguntas</h2>
                      <span class="question-state">Pregunta <span class='qIdx'>1</span> de <span class='qLength'>10</span></span>
                    </div>
                    <div class="content-question">
                      <label class="title fs15">¿Considera que los cambios realizados en distintas rutas del transporte público son…?</label>
                      <label class="label-point">Puntos: 10</label>

                      <div class="content-point">
                        <div class="point-item">
                          <img src={POLITICO1} alt="POLITICO" />
                          <span>Ricardo Sheffield</span>
                        </div>
                        <input type="text" readonly="" value="0" />
                        <div class="point-option">
                          <div class="plus">+</div>
                          <div class="less">-</div>
                        </div>
                      </div>
                      <div class="content-point">
                        <div class="point-item">
                        <img src={POLITICO2} alt="POLITICO" />
                          <span>Claudia Sheinbaum</span>
                        </div>
                        <input type="text" readonly="" value="0" />
                        <div class="point-option">
                          <div class="plus">+</div>
                          <div class="less">-</div>
                        </div>
                      </div>
                      <div class="content-point">
                        <div class="point-item">
                        <img src={POLITICO3} alt="POLITICO" />
                          <span>Libia García</span>
                        </div>
                        <input type="text" readonly="" value="0" />
                        <div class="point-option">
                          <div class="plus">+</div>
                          <div class="less">-</div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </BoxCode>

      </div>
    </Main>
  )
}