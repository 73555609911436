import React from "react";
// Layout
import Main from "../../../components/layout/Main";

// Component
import BoxCode from "../../../components/elements/BoxCode";
import Button from "../../../components/elements/Button";
import RadioLabel from "../../../components/elements/RadioLabel";

export default function Botones() {

    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Radio label' code={{}}>
                    <RadioLabel />
                </BoxCode>
            </div>
        </Main>
    );
}
