import { useEffect, useState } from "react";
import CheckboxLabel from "../../../components/elements/CheckboxLabel";
import GridStatus from "../../../components/elements/GridStatus";
import Col from "../../../components/elements/Table/Col";
import Row from "../../../components/elements/Table/Row";
import Table from "../../../components/elements/Table/Table";
import Main from "../../../components/layout/Main";

export default function Pruebas() {

    const [withParent, setWithParent] = useState('');

  const getLineas = () => {
    let item = [];

    for (let i = 79; i < 101; i++) {
      item.push({
        content: (
          <div>
            <p>Prueba{i}</p>
          </div>
        ),
        background: "#f00",
        color: "#fff",
      });
    }

    return item;
  };

  return (
    <>
      <GridStatus data={getLineas()} columns={28} />
    </>
  );
}
