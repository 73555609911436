
import React from "react";

import { useEffect, useState } from "react";

export default function ListingItem({ children, type, header, className }) {

    const [child, setChild] = useState(children);

    useEffect(() => {

        switch (type) {
            case 'table':
                if (Array.isArray(child)) {
                    let arr = [];

                    child.forEach(child => {
                        header ? arr.push({...child, props: {...child.props, header: true, type}}) : arr.push({...child, props: {...child.props, type}});
                    })
                    setChild(arr)
                } else {
                    setChild({...child, props: {...child.props, header: true, type}})
                }
                break;
        
            default:
                break;
        }    

    }, [type, header])

    switch (type) {
        case 'table':

        if (header) {
            return (
                <thead className={`item-listing ${className !== undefined ? className : ''}`}>
                    <tr>{child}</tr>
                </thead>
            );
        } else {
            return (
                <tbody className={`item-listing ${className !== undefined ? className : ''}`}>
                    <tr>{child}</tr>
                </tbody> 
            );
        }
    
        default:
            return <div className={`item-listing ${className !== undefined ? className : ''}`}>{children}</div>;
    }
}