import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, Scatter, LabelList } from 'recharts';

const data = [
  {
    name: 'CAMP1',
    inicio: 4,
    fin: 15,
    max: 33,
    id: 6,
    fecha_inicio: '2023-01-01',
    fecha_fin: '2023-12-31',
  },
  {
    name: 'CAMP2',
    inicio: 15,
    fin: 33,
    max: 4,
    id: 7,
    fecha_inicio: '2023-01-01',
    fecha_fin: '2023-12-31',
  }
];

export default function HorizontalBarExample() {
  return (
    <>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }} layout='vertical'>
          <YAxis dataKey="name" type='category' xAxisId='bar' tickLine={false} axisLine={false} />
          <XAxis dataKey="max" type='number' xAxisId='bar' domain={[0, 52]} hide={true} />

          <Tooltip content={CustomTooltip} />
          
          <Bar name="inicio" dataKey="inicio" stackId="gantt" fill="#FFFFFF" xAxisId='bar' barSize={8} />
          <Bar name="fin" dataKey="fin" stackId="gantt" fill="#71d44c" xAxisId='bar' barSize={8} >
            <LabelList dataKey="fin" content={renderCustomizedLabel} />
          </Bar>
          <Bar name="max" dataKey="max" stackId="gantt" fill="#FFFFFF" xAxisId='bar' barSize={8}>
            <LabelList dataKey="fin" content={renderCustomizedLabel} />
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
}

const renderCustomizedLabel = ({ x, y, width, height, value }) => {
  const radius = 8;

  return (
    <g>
      <circle cx={x} cy={y + 4} r={radius} fill="#71d44c" />
    </g>
  );
};


const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <div className='label flex g15 just-sb w100'>
          <span>FECHA INICIO</span>
          <span>-</span>
          <span>{payload[0].payload.fecha_inicio}</span>
        </div>
        <div className='label flex g15 just-sb w100'>
          <span>FECHA FIN</span>
          <span>-</span>
          <span>{payload[1].payload.fecha_fin}</span>
        </div>
      </div>
    );
  }

  return null;
};