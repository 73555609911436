import BoxLogin from "../../../components/elements/BoxLogin";
import Main from "../../../components/layout/Main";

import Logo from "../../../assets/imgs/logo-horizontal.svg";
import LogoOp from "../../../assets/LogoOp";
import BoxCode from "../../../components/elements/BoxCode";
import Input from "../../../components/elements/Input";
import Button from "../../../components/elements/Button";
import InputWithLabel from "../../../components/elements/InputWithLabel";

export default function BoxLoginExample() {
    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title="Box login">
                    <BoxLogin logo={Logo} title="Login">
                        <form action="" onSubmit={(e) => e.preventDefault()}>
                            <div className="row">
                                <InputWithLabel type="text" textLabel="Usuario" classInput="" classLabel="login-label" spellCheck="false" />
                            </div>
                            <div className="row">
                                <InputWithLabel type="password" textLabel="Contraseña" classLabel="login-label" classContent="" />
                            </div>

                            <div className="row">
                                <Button className="primary">Ingresar</Button>
                            </div>
                        </form>
                    </BoxLogin>
                </BoxCode>
            </div>
        </Main>
    );
}
