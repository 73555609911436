import React, { createElement, Fragment } from "react";

export const useCreateElementImage = (img, alt, className) => {

    if (img != undefined && img != '') {
        if (typeof img == 'object') {
            return createElement(img.type, {
                ...img.props,
                alt: img.props.alt || alt,
                className: img.props.className ?? className ?? null,
            })
        } else {
            return <img src={img} alt={alt} className={className} />;
        }
    } else {
        return <></>;
    }
}