import React, { Fragment } from "react";

export default function StaticSidebar({
  listClass = "",
  children,
  sidebarWidth,
}) {
  return (
    <div className="static-sidebar" style={{ minWidth: `${sidebarWidth}px` }}>
      <div className="static-sidebar-content">
        <ul className={listClass}>{children}</ul>
      </div>
    </div>
  );
}
