import React, { Fragment, useState } from "react";

export default function Tab({
  className = "",
  nombre,
  isActive,
  onClick,
  icono,
  onClose=undefined
}) {

  

  return (
    <>
      <div
        className={`tab ${className} ${isActive == nombre ? "active" : ""} h5`}
        onClick={onClick}
      >
        {`${icono ? icono : ""} ${nombre}`}

       {onClose && <span className="close" onClick={onClose} >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              fill="currentColor"
              className="bi bi-x"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M.146 0a.5.5 0 0 0-.354.854L7.293 8l-7.5 7.5a.5.5 0 1 0
                .708.708L8 8.707l7.5 7.5a.5.5 0 0
                0 .708-.708L8.707 8l7.5-7.5a.5.5 0 0
                0-.708-.708L8 7.293.854.146A.5.5 0 0 0
                .146 0z"
              />
            </svg>
          </span>
        }

      </div>
    </>
  );
}
