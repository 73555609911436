import React from "react";
// Layout
import Main from "../../../components/layout/Main";

// Component
import Breadcrumb from "../../../components/elements/Breadcrumb";
import BreadcrumbItem from "../../../components/elements/BreadcrumbItem";
import BoxCode from "../../../components/elements/BoxCode";

export default function BreadcrumbExample() {

    const code = `
import Breadcrumb from "../../../components/elements/Breadcrumb";
import BreadcrumbItem from "../../../components/elements/BreadcrumbItem";

export default function BreadcrumbExample() {
    return (
        <Breadcrumb>
                        <BreadcrumbItem>Home</BreadcrumbItem>
                        <BreadcrumbItem>Componentes</BreadcrumbItem>
                        <BreadcrumbItem isActive={true}>Breadcrumb</BreadcrumbItem>
                </Breadcrumb>
    );
}`;

    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Breadcrumb' classBox='gray6' code={code}>
                    <Breadcrumb>
                        <BreadcrumbItem>Home</BreadcrumbItem>
                        <BreadcrumbItem>Componentes</BreadcrumbItem>
                        <BreadcrumbItem isActive={true}>Breadcrumb</BreadcrumbItem>
                    </Breadcrumb>
                </BoxCode>
            </div>
        </Main>
    );
}
