
import React from "react";

import { useEffect, useState } from "react";

export default function Listing({ children, type, className }) {

    const [child, setChild] = useState(children);
    
    useEffect(() => {
        switch (type) {
            case 'table':
                if (Array.isArray(child)) {
                    let arr = [];

                    child.forEach((child, index) => index == 0 ? arr.push({...child, props: {...child.props, header: true, type}}) : arr.push({...child, props: {...child.props, type}}));

                    setChild(arr)
                } else {
                    setChild({...child, props: {...child.props, header: true, type}})
                }
                break;
        
            default:
                break;
        }
    }, [])

    switch (type) {
        case 'table':
            return <table className={`listing ${className !== undefined ? className : ''}`}>{child}</table>;
    
        default:
            return <ul className={`listing ${className !== undefined ? className : ''}`}>{children}</ul>;
    }
}