
import { Navigate, Route, Routes } from "react-router-dom";
import AreaExample from "../pages/Area";
import BarExample from "../pages/Bar";

import LineExample from "../pages/Line";
import HorizontalBarExample from "../pages/BarHorizontal";

export default function GraficasRoutes() {
  return (
    <Routes>
      <Route path="/line" element={<LineExample />} />
      <Route path="/bar" element={<BarExample />} />
      <Route path="/horizontal-bar" element={<HorizontalBarExample />} />
      <Route path="/area" element={<AreaExample />} />
      <Route path="/" element={<Navigate to={"line"} />} />
    </Routes>
  );
}