import React, { Fragment,useState } from "react";


export default function TabContent({ nombre,children,isActive, className='' }) {
    return (
        <>
        <div id={nombre} className={`${className} tab-content ${isActive == nombre ? `active ${className}` : ''} `}>
            {children}
        </div>
        </>
    );
}