import React, { useState, useEffect, useRef, Fragment } from "react";
import Button from "./Button";

// Image


export default function Sidebar({open,setOpen, children,title,save, cancel,onClickSave,onClickCancel,width,showButtons}) {
    const ref = useRef(null);
    //abrir menu
    //const [open, setOpen] = useState(isOpen);
    //abrir menu
    const toggleMenu = (isOpen) => {
        setOpen(isOpen);
    }

    useEffect(() => {
        function handleClickOutside(event) {
    
          if ((ref.current && !ref.current.contains(event.target))) {
            setOpen(false);
          }
        }
        
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);


    return (
        <>
        <div ref={ref} id="mySidenav" className="sidenav" style={open? {width: width?? '350px'} : {width: '0px'}} >
            <div className="sidenav-top">
                    { 
                       title
                    }
                <a href="#" className="close" onClick={toggleMenu.bind(this, !open)}>&times;</a>

            </div>
            <div className="sidenav-contenido" style={{height: showButtons? 'calc(100% - 130px)' : 'calc(100% - 100px)' }}>
                { children }
                
            </div>

            <div className="sidenav-bottom" style={showButtons? {display: 'flex'} : {display: 'none'}}>
                    {
                        cancel && <Button className="caution" onClick={onClickCancel ? onClickCancel : toggleMenu.bind(this, !open)}>{cancel}</Button>
                    }

                    {
                        save && <Button className="primary" onClick={onClickSave? onClickSave : null}>{save}</Button>
                    }
            </div>


        </div>
        
       
        </>
       

    );
}