
import BoxCode from "../../../components/elements/BoxCode";
import Main from "../../../components/layout/Main";

import PRI from "../../../assets/imgs/pri.png";
import PAN from "../../../assets/imgs/pan.png";
import MORENA from "../../../assets/imgs/morena.png";
import POLITICO1 from "../../../assets/imgs/1.png";
import POLITICO2 from "../../../assets/imgs/2.png";
import POLITICO3 from "../../../assets/imgs/3.png";

export default function OpcionSimpleExample() {
  return (
    <Main>
      <div className="section elementos">
        <h5 className="c-green">Campañas</h5>
        <h2>Encuestas</h2>

        {/* OPCION SIMPLE */}
        <BoxCode title='Opcion Simple'>
          <div className="body">
            <form className="main-campanas" action="" method="POST" id="form">

              {/* Middle */}
              <div className="content-middle height-header-subheader">
                <div className="middle">
                  <div className="cuestionario">
                    <div className="content-title">
                      <h2 className="title">Ayúdanos contestando las siguientes preguntas</h2>
                      <span className="question-state">Pregunta <span>1</span> de <span>10</span></span>
                    </div>
                    <div className="content-question">
                      <label className="title">¿Considera que los cambios realizados en distintas rutas del transporte público son…?</label>
                      <div className="group col">
                        <div className="option">
                          <input type="radio" name="p1" value="Muy Buenos" required="" />
                          <label className="choice">Muy Buenos</label>
                        </div>
                        <div className="option">
                          <input type="radio" name="p1" value="Buenos" required="" />
                          <label className="choice">Buenos</label>
                        </div>
                        <div className="option">
                          <input type="radio" name="p1" value="Malos" required="" />
                          <label className="choice">Malos</label>
                        </div>
                        <div className="option">
                          <input type="radio" name="p1" value="Muy Malos" required="" />
                          <label className="choice">Muy Malos</label>
                        </div>
                        <div className="option">
                          <input type="radio" name="p1" value="Muy Malos" required="" />
                          <label className="choice">Está igual</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </BoxCode>

        {/* OPCION SIMPLE */}
        <BoxCode title='Opcion Simple Pack'>
          <div className="body">
            <form className="main-campanas" action="" method="POST" id="form">

              {/* Middle */}
              <div className="content-middle height-header-subheader">
                <div className="middle">
                  <div className="cuestionario">
                    <div className="content-title">
                      <h2 className="title">Ayúdanos contestando las siguientes preguntas</h2>
                      <span className="question-state">Pregunta <span>1</span> de <span>10</span></span>
                    </div>
                    <div className="content-question">
                      <label className="title fs15">En su opinión, ¿se debería mantener o cambiar el partido político que está gobernando?</label>

                      <div className="content-group-col">
                        <label className="subtitle">El gobierno municipal de León:</label>
                        <div className="group just-c">
                          <div className="option">
                            <input type="radio" name="p1" value="Mantener" required="" />
                            <label className="choice">Mantener</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p1" value="Cambiar" required="" />
                            <label className="choice">Cambiar</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p1" value="No Sabe" required="" />
                            <label className="choice">No Sabe</label>
                          </div>
                        </div>
                      </div>
                      <div className="content-group-col">
                        <label className="subtitle">El gobierno del Estado de Guanajuato:</label>
                        <div className="group just-c">
                          <div className="option">
                            <input type="radio" name="p2" value="Mantener" required="" />
                            <label className="choice">Mantener</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p2" value="Cambiar" required="" />
                            <label className="choice">Cambiar</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p2" value="No Sabe" required="" />
                            <label className="choice">No Sabe</label>
                          </div>
                        </div>
                      </div>
                      <div className="content-group-col">
                        <label className="subtitle">El gobierno de la República:</label>
                        <div className="group just-c">
                          <div className="option">
                            <input type="radio" name="p3" value="Mantener" required="" />
                            <label className="choice">Mantener</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p3" value="Cambiar" required="" />
                            <label className="choice">Cambiar</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p3" value="No Sabe" required="" />
                            <label className="choice">No Sabe</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </BoxCode>

        {/* OPCION SIMPLE */}
        <BoxCode title='Opcion Simple Pack Imagen'>
          <div className="body">
            <form className="main-campanas" action="" method="POST" id="form">

              {/* Middle */}
              <div className="content-middle height-header-subheader">
                <div className="middle">
                  <div className="cuestionario">
                    <div className="content-title">
                      <h2 className="title">Ayúdanos contestando las siguientes preguntas</h2>
                      <span className="question-state">Pregunta <span>1</span> de <span>10</span></span>
                    </div>
                    <div className="content-question">
                      <label className="title fs15">En su opinión, ¿se debería mantener o cambiar el partido político que está gobernando?</label>

                      <div className="content-group-col">
                        <label className="subtitle">El gobierno municipal de León:</label>
                        <div className="group just-c">
                          <div className="option">
                            <input type="radio" name="p1" value="PRI" required="" />
                            <label className="choice">
                              <img src={PRI} alt="PRI" />
                            </label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p1" value="PAN" required="" />
                            <label className="choice">
                              <img src={PAN} alt="PAN" />
                            </label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p1" value="MORENA" required="" />
                            <label className="choice">
                              <img src={MORENA} alt="MORENA" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="content-group-col">
                        <label className="subtitle">El gobierno del Estado de Guanajuato:</label>
                        <div className="group just-c">
                          <div className="option">
                            <input type="radio" name="p2" value="PRI" required="" />
                            <label className="choice">
                              <img src={PRI} alt="PRI" />
                            </label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p2" value="PAN" required="" />
                            <label className="choice">
                              <img src={PAN} alt="PAN" />
                            </label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p2" value="MORENA" required="" />
                            <label className="choice">
                              <img src={MORENA} alt="MORENA" />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="content-group-col">
                        <label className="subtitle">El gobierno de la República:</label>
                        <div className="group just-c">
                          <div className="option">
                            <input type="radio" name="p3" value="PRI" required="" />
                            <label className="choice">
                              <img src={PRI} alt="PRI" />
                            </label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p3" value="PAN" required="" />
                            <label className="choice">
                              <img src={PAN} alt="PAN" />
                            </label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p3" value="MORENA" required="" />
                            <label className="choice">
                              <img src={MORENA} alt="MORENA" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </BoxCode>

        {/* OPCION SIMPLE */}
        <BoxCode title='Opcion Simple Pack Imagen Text'>
          <div className="body">
            <form className="main-campanas" action="" method="POST" id="form">

              {/* Middle */}
              <div className="content-middle height-header-subheader">
                <div className="middle">
                  <div className="cuestionario">
                    <div className="content-title">
                      <h2 className="title">NOS GUSTARIA SABER TU OPINIÓN</h2>
                      <span className="question-state">Pregunta <span>1</span> de <span>10</span></span>
                    </div>
                    <div className="content-question">
                      <label className="title fs15">¿Quién crees tú que va a <span>ser el próximo</span> candidato de <span>Morena</span> a la Presidencia?</label>

                      <div className="content-group-col">
                        <label className="subtitle">El gobierno municipal de León:</label>
                        <div className="group just-c">
                          <div className="option">
                            <input type="radio" name="p1" value="POLITICO" required="" />
                            <label className="choice imagen">
                              <img src={POLITICO1} alt="POLITICO" />
                              Ricardo Sheffield
                            </label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p1" value="POLITICO" required="" />
                            <label className="choice imagen">
                              <img src={POLITICO2} alt="POLITICO" />
                              Alejandra Gutiérrez
                            </label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p1" value="POLITICO" required="" />
                            <label className="choice imagen">
                              <img src={POLITICO3} alt="POLITICO" />
                              Libia García
                            </label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p1" value="POLITICO" required="" />
                            <label className="choice imagen">
                              <img src={POLITICO1} alt="POLITICO" />
                              Ricardo Sheffield
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </BoxCode>

        {/* OPCION SIMPLE */}
        <BoxCode title='Opcion Simple Pack Row'>
          <div className="body">
            <form className="main-campanas" action="" method="POST" id="form">

              {/* Middle */}
              <div className="content-middle height-header-subheader">
                <div className="middle">
                  <div className="cuestionario">
                    <div className="content-title">
                      <h2 className="title">Ayúdanos contestando las siguientes preguntas</h2>
                      <span className="question-state">Pregunta <span>1</span> de <span>10</span></span>
                    </div>
                    <div className="content-question">
                      <label className="title fs15">En su opinión, ¿se debería mantener o cambiar el partido político que está gobernando?</label>

                      <div className="content-group-row">
                        <label className="subtitle">Adán López</label>
                        <div className="group">
                          <div className="option">
                            <input type="radio" name="p1" value="Si" required=""/>
                            <label className="choice">Si</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p1" value="No" required=""/>
                            <label className="choice">No</label>
                          </div>
                        </div>
                      </div>
                      <div className="content-group-row">
                        <label className="subtitle">Libia García</label>
                        <div className="group">
                          <div className="option">
                            <input type="radio" name="p2" value="Si" required=""/>
                            <label className="choice">Si</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p2" value="No" required=""/>
                            <label className="choice">No</label>
                          </div>
                        </div>
                      </div>
                      <div className="content-group-row">
                        <label className="subtitle">Alejandra Gutiérrez</label>
                        <div className="group">
                          <div className="option">
                            <input type="radio" name="p3" value="Si" required=""/>
                            <label className="choice">Si</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p3" value="No" required=""/>
                            <label className="choice">No</label>
                          </div>
                        </div>
                      </div>
                      <div className="content-group-row">
                        <label className="subtitle">Luis Ayala</label>
                        <div className="group">
                          <div className="option">
                            <input type="radio" name="p4" value="Si" required=""/>
                            <label className="choice">Si</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p4" value="No" required=""/>
                            <label className="choice">No</label>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </BoxCode>

        {/* OPCION SIMPLE */}
        <BoxCode title='Opcion Simple Pack Row Imagen'>
          <div className="body">
            <form className="main-campanas" action="" method="POST" id="form">

              {/* Middle */}
              <div className="content-middle height-header-subheader">
                <div className="middle">
                  <div className="cuestionario">
                    <div className="content-title">
                      <h2 className="title">Ayúdanos contestando las siguientes preguntas</h2>
                      <span className="question-state">Pregunta <span>1</span> de <span>10</span></span>
                    </div>
                    <div className="content-question">
                      <label className="title fs15">En su opinión, ¿se debería mantener o cambiar el partido político que está gobernando?</label>

                      <div className="content-group-row">
                        <label className="subtitle">
                          <img src={POLITICO1} alt="POLITICO" />
                        </label>
                        <div className="group">
                          <div className="option">
                            <input type="radio" name="p1" value="Si" required="" />
                            <label className="choice">Si</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p1" value="No" required="" />
                            <label className="choice">No</label>
                          </div>
                        </div>
                      </div>
                      <div className="content-group-row">
                        <label className="subtitle">
                          <img src={POLITICO2} alt="POLITICO" />
                        </label>
                        <div className="group">
                          <div className="option">
                            <input type="radio" name="p2" value="Si" required="" />
                            <label className="choice">Si</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p2" value="No" required="" />
                            <label className="choice">No</label>
                          </div>
                        </div>
                      </div>
                      <div className="content-group-row">
                        <label className="subtitle">
                          <img src={POLITICO3} alt="POLITICO" />
                        </label>
                        <div className="group">
                          <div className="option">
                            <input type="radio" name="p3" value="Si" required="" />
                            <label className="choice">Si</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p3" value="No" required="" />
                            <label className="choice">No</label>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </BoxCode>

        {/* OPCION SIMPLE */}
        <BoxCode title='Opcion Simple Pack Row Imagen Text'>
          <div className="body">
            <form className="main-campanas" action="" method="POST" id="form">

              {/* Middle */}
              <div className="content-middle height-header-subheader">
                <div className="middle">
                  <div className="cuestionario">
                    <div className="content-title">
                      <h2 className="title">Ayúdanos contestando las siguientes preguntas</h2>
                      <span className="question-state">Pregunta <span>1</span> de <span>10</span></span>
                    </div>
                    <div className="content-question">
                      <label className="title fs15">En su opinión, ¿se debería mantener o cambiar el partido político que está gobernando?</label>

                      <div className="content-group-row">
                        <label className="subtitle wx120">
                          <img src={POLITICO1} alt="POLITICO" />
                          Ricardo Sheffield
                        </label>
                        <div className="group">
                          <div className="option">
                            <input type="radio" name="p1" value="Masculino" required="" />
                            <label className="choice">1</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p1" value="Masculino" required="" />
                            <label className="choice">2</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p1" value="Masculino" required="" />
                            <label className="choice">3</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p1" value="Masculino" required="" />
                            <label className="choice">4</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p1" value="Masculino" required="" />
                            <label className="choice">5</label>
                          </div>
                        </div>
                      </div>
                      <div className="content-group-row">
                        <label className="subtitle wx120">
                          <img src={POLITICO2} alt="POLITICO" />
                          Alejandra Gutiérrez
                        </label>
                        <div className="group">
                          <div className="option">
                            <input type="radio" name="p2" value="Masculino" required="" />
                            <label className="choice">1</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p2" value="Masculino" required="" />
                            <label className="choice">2</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p2" value="Masculino" required="" />
                            <label className="choice">3</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p2" value="Masculino" required="" />
                            <label className="choice">4</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p2" value="Masculino" required="" />
                            <label className="choice">5</label>
                          </div>
                        </div>
                      </div>
                      <div className="content-group-row">
                        <label className="subtitle wx120">
                          <img src={POLITICO3} alt="POLITICO" />
                          Libia García
                        </label>
                        <div className="group">
                          <div className="option">
                            <input type="radio" name="p3" value="Masculino" required="" />
                            <label className="choice">1</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p3" value="Masculino" required="" />
                            <label className="choice">2</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p3" value="Masculino" required="" />
                            <label className="choice">3</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p3" value="Masculino" required="" />
                            <label className="choice">4</label>
                          </div>
                          <div className="option">
                            <input type="radio" name="p3" value="Masculino" required="" />
                            <label className="choice">5</label>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </BoxCode>
      </div>
    </Main>
  );
}