import React, { useRef } from "react";
import { Fragment, useEffect, useState } from "react";
import Input from "./Input";

export default function Dropdown({
  items = [],
  search,
  placeholder = "Todos",
  titulo,
  onSelectOption,
  id,
  disabled = false,
  isList = false,
  showSelected = false,
  create = false,
  createElement = () => { },
  dropClass = "",
  classCollpsed = '',
  name = "",
  dropId = ''
}) {

  const elementCollapsed = useRef(null);

  const [style, setStyle] = useState({ top: 0, left: 0 });
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(items);
  const [searchValue, setSearchValue] = useState("");
  const [filteredItems, setFilteredItems] = useState(items);
  const [itemsList, setItemsList] = useState(items);
  const [labelDisplay, setLabelDisplay] = useState("");

  useEffect(() => {
    setFilteredItems(items);
    setItemsList(items);
    setSelected(items);
  }, [items]);

  useEffect(() => { handlePosition() }, []);


  useEffect(() => {
    if (id === undefined) return;

    setSelected(items.filter((item) => item.id == id)[0]);
  }, [id, items]);

  useEffect(() => {
    labelSelected();
  }, [filteredItems, selected]);

  useEffect(() => {
    if (searchValue.length > 0) {
      const filtered = itemsList.filter((item) =>
        item.value.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredItems(filtered);
    } else {
      setFilteredItems(itemsList);
    }
  }, [searchValue]);

  useEffect(() => {
    if (open && search) {
      // set focus on input element when dropdown is opened
      document.getElementById("dropdown-search").focus();
    }
  }, [open]);

  //close dropdown when clicked outside
  useEffect(() => {
    const closeDropdown = (e) => {
      if (e.target.closest(".dropdown") === null) {
        setOpen(false);
      }
    };
    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  }, []);

  //close dropdown when escape key is pressed
  useEffect(() => {
    const closeDropdown = (e) => {
      if (
        e.key === "Escape" ||
        e.key === "Esc" ||
        e.keyCode === 27 ||
        e.key === "Tab"
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("keydown", closeDropdown);
    return () => document.removeEventListener("keydown", closeDropdown);
  }, []);

  const handlePosition = () => {
    let target = elementCollapsed.current;
    let s = { ...style };
    if ((target.offsetTop + (target.clientHeight - 10 + 200)) > window.screen.height) {
      s.top = (target.offsetTop - 210);
    } else {
      s.top = (target.offsetTop + (target.clientHeight - 10));
    }
    s.left = target.offsetLeft;
    s.width = target.clientWidth;

    setStyle(s);
  }

  const toggleDropdown = (e) => {
    e.stopPropagation();

    handlePosition();

    if (
      isList &&
      open &&
      !["dropdown  on", "box g20 flex gray6", "dropdown-collpsed"].includes(
        e.target.parentElement.className
      )
    ) {
      return;
    }

    var targetDiv = document.getElementsByClassName("dropdown-content");

    if (targetDiv.length > 0) {
      for (var i = 0; i < targetDiv.length; i++) {
        //console.log(targetDiv[i]);
        if (targetDiv[i].style.display === "block") {
          targetDiv[i].style.display = "none";
        }
        if (i === targetDiv.length - 1) {
          setOpen(!open);
        }
      }
    } else {
      setOpen(!open);
    }
  };

  const handleItemClick = (item) => {
    if (isList) {
      const updatedItems = itemsList.map((i) => {
        if (i.id === item.id) {
          return { ...i, checked: !i.checked };
        }
        return i;
      });
      const updateFilterItems = filteredItems.map((i) => {
        if (i.id === item.id) {
          return { ...i, checked: !i.checked };
        }
        return i;
      });

      if (onSelectOption !== undefined) {
        onSelectOption(updatedItems);
      }

      setFilteredItems(updateFilterItems);
      setSelected(updatedItems);
      setItemsList(updatedItems);
      return;
    }

    setOpen(false);
    setSelected(item);
    if (onSelectOption !== undefined) {
      onSelectOption(item);
    }
  };

  const labelSelected = () => {
    if (!isList) {
      setLabelDisplay(
        (titulo ? titulo + ": " : "") + (selected?.value || placeholder)
      );
      return;
    }

    let numberItems = selected.filter((item) => item.checked).length;
    //console.log('numberItems', numberItems);
    if (numberItems > 0) {
      if (showSelected) {
        setLabelDisplay(
          (titulo ? titulo + ": " : "") +
          selected
            .filter((item) => item.checked)
            .map((item) => item.value)
            .join(", ")
        );
        //console.log('1.-setLabelDisplay', (titulo ? (titulo+': ') : '')+(selected.filter(item=>item.checked).map(item => item.value).join(', ')))
        return;
      } else {
        setLabelDisplay(
          (titulo ? titulo + ": " : "") +
          (selected.filter((item) => item.checked).length + " seleccionados")
        );
        //console.log('2.-setLabelDisplay', (titulo ? (titulo+': ') : '')+(selected.filter(item=>item.checked).length + " seleccionados"))
        return;
      }
    }
    setLabelDisplay((titulo ? titulo + ": " : "") + placeholder);
    //console.log('3.-setLabelDisplay', (titulo ? (titulo+': ') : '')+placeholder)
  };

  function handleCreate({ value }, input) {
    if (value != "") {
      setFilteredItems([
        ...filteredItems,
        { id: filteredItems[filteredItems.length - 1].id++, value },
      ]);
      input.current.value = "";
      createElement({
        id: filteredItems[filteredItems.length - 1].id++,
        value,
      });
    }
  }

  return (
    <>
      <div
        className={`dropdown ${dropClass} ${disabled ? "disabled" : ""} ${open ? "on" : ""
          }`}
      >
        <div ref={elementCollapsed} className={`dropdown-collpsed ${classCollpsed}`} data-id={dropId} onClick={(e) => toggleDropdown(e)}>
          <input
            type="hidden"
            name="dropdown"
            value={selected ? selected.id : ""}
          />
          <input
            type="hidden"
            name={name || "value"}
            value={selected ? selected.value : ""}
          />
          {/* <span>{selected?.value || placeholder}</span> */}
          <span class="dropdown-display">{labelDisplay}</span>
          <div className="arrow">
            <svg viewBox="0 0 86.6 75">
              <polygon points="0.2,74.9 43.3,0.2 86.4,74.9" />
              <path d="M43.3,0.5l42.9,74.2H0.4L43.3,0.5 M43.3,0L0,75h86.6L43.3,0L43.3,0z" />
            </svg>
          </div>
        </div>
        <div className={`dropdown-content ${!open && "none"}`} style={style}>
          {search && (
            <Input
              type="search"
              id="dropdown-search"
              placeholder="Buscar..."
              onChange={(e) => setSearchValue(e.target.value)}
            />
          )}

          {filteredItems ? (
            filteredItems.map((item, index) => (
              <div
                className="dropdown-item"
                key={index}
                onClick={() => {
                  handleItemClick(item);
                }}
              >
                {isList && (
                  <input
                    type="checkbox"
                    style={{ marginRight: "5px", width: "auto" }}
                    checked={!!item.checked}
                    onChange={() => {
                      handleItemClick(item);
                    }}
                  />
                )}
                <span>{item.value}</span>
              </div>
            ))
          ) : (
            <div className="dropdown-item">No data found</div>
          )}
          {!create ? (
            <></>
          ) : (
            <Input
              type="create"
              id="dropdown-create"
              placeholder="Agregar"
              onClickIcon={handleCreate}
            />
          )}
        </div>
      </div>
    </>
  );
}
