import React from "react";

export default function Item({ children, type, header, className }) {
    
    switch (type) {
        case 'table':
            return header ? <th className={className !== undefined ? className : ''}>{children}</th> : <td className={className !== undefined ? className : ''}>{children}</td>;
    
        default:
            return <li className={className !== undefined ? className : ''}>{children}</li>;
    }
}