
import BoxCode from "../../../components/elements/BoxCode";
import Main from "../../../components/layout/Main";

export default function OpcionMultipleExample() {
  return (
    <Main>
      <div className="section elementos">
        <h5 className="c-green">Campañas</h5>
        <h2>Encuestas</h2>

        {/* OPCION MULTIPLE */}
        <BoxCode title='Opcion Multiple'>
          <div className="body">
            <form className="main-campanas" action="" method="POST" id="form">

              {/* Middle */}
              <div className="content-middle height-header-subheader">
                <div className="middle">
                  <div className="cuestionario">
                    <div className="content-title">
                      <h2 className="title">Ayúdanos contestando las siguientes preguntas</h2>
                      <span className="question-state">Pregunta <span>1</span> de <span>10</span></span>
                    </div>
                    <div className="content-question">
                      <label className="title fs15">¿Tú o alguien que conoces ha sentido alguno de estos síntomas? (Selecciona una o varias de las siguientes opciones)</label>
                      <div className="group col">
                        <div className="option">
                          <input type="checkbox" name="p1" value="Pérdida de interés en actividades" required="" />
                          <label className="choice">Pérdida de interés en actividades</label>
                        </div>
                        <div className="option">
                          <input type="checkbox" name="p1" value="Pérdida de interés en actividades" required="" />
                          <label className="choice">Aislamiento</label>
                        </div>
                        <div className="option">
                          <input type="checkbox" name="p1" value="Ansiedad" required="" />
                          <label className="choice">Ansiedad</label>
                        </div>
                        <div className="option">
                          <input type="checkbox" name="p1" value="Conductas impulsivas" required="" />
                          <label className="choice">Conductas impulsivas</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="content-footer">
                <input type="submit" class="btn-submit" value="Click Aquí para Continuar"/>
              </div>
            </form>
          </div>
        </BoxCode>
      </div>
    </Main>
  );
}