import React, { useState, Fragment, createElement } from "react";

export default function Nav({ children, navClass, data = [], logo, paddingY, paddingX, wrapperWidth, LogoContent, pathname = '' }) {

    const [isCollapse, setIsCollapse] = useState(true);
    const [isCollapseSesion, setIsCollapseSesion] = useState(true);

    const getLogo = () => {
        return (
            logo != undefined ?
                typeof logo == 'string' ?
                    <img src={logo} alt="Logo" width='100px' /> :
                    logo :
                <>
                    wui <span>beta</span>
                </>
        );
    }

    const getLinks = () => {
        return (
            data.map((item, index) => {
                return typeof item.link == 'object' ?
                    <li key={index}>
                        {createElement(item.link.type, {
                            ...item.link.props,
                            className: `${item.class ?? ''} ${item.link.props.className ?? ''} ${pathname.includes(item.active) ? 'active' : ''}`
                        }, item.text)
                        }
                    </li> :
                    item.link != undefined ?
                        <li key={index}>
                            <a href={item.link} className={`${item.class ?? ''} ${pathname.includes(item.active) ? 'active' : ''}`}>{item.text}</a>
                        </li> :
                        item.type == 'logout' ?
                            <>
                                <li key={index} className="position-relative content-logout">
                                    <div className="flex g10">
                                        <div className="logout-img flex align-c just-c">{item.text && <span>{item.text.toUpperCase().slice(0, 2)}</span>}</div>
                                        <div className="flex align-c g10 cursor-pointer" onClick={() => setIsCollapseSesion(!isCollapseSesion)}>
                                            {item.text && <span className="fs14">{item.text}</span>}
                                            <div class={`arrow ${!isCollapseSesion && 'on'}`}>
                                                <svg viewBox="0 0 86.6 75">
                                                    <polygon points="0.2,74.9 43.3,0.2 86.4,74.9"></polygon>
                                                    <path d="M43.3,0.5l42.9,74.2H0.4L43.3,0.5 M43.3,0L0,75h86.6L43.3,0L43.3,0z"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`logout-drop cursor-pointer ${isCollapseSesion && 'none'}`} onClick={item.onClick}>
                                        <span>Cerrar Sesión</span>
                                    </div>
                                </li>
                            </> :
                            <li key={index} onClick={item.onClick}>
                                <span className={`cursor-pointer ${item.class ?? ''} ${pathname.includes(item.active) ? 'active' : ''}`}>{item.text}</span>
                            </li>
            })
        );
    }

    return (
        <nav className={`nav ${navClass ?? ''} ${!isCollapse ? 'on' : ''}`}>
            <div className="wrapper" style={{
                paddingTop: typeof paddingY == 'string' ? paddingY : `${paddingY != undefined ? `${paddingY}px` : null}`,
                paddingBottom: typeof paddingY == 'string' ? paddingY : `${paddingY != undefined ? `${paddingY}px` : null}`,
                paddingLeft: typeof paddingX == 'string' ? paddingX : `${paddingX != undefined ? `${paddingX}px` : null}`,
                paddingRight: typeof paddingX == 'string' ? paddingX : `${paddingX != undefined ? `${paddingX}px` : null}`,
                maxWidth: typeof wrapperWidth == 'string' ? wrapperWidth : `${wrapperWidth != undefined ? `${wrapperWidth}px` : null}`
            }}
            >

                {/* Logo */}
                {LogoContent != undefined ?
                    typeof LogoContent == 'string' ?
                        <div>
                            <a className="logo" href={LogoContent}>
                                {getLogo()}
                            </a>
                        </div> :
                        <div>
                            {createElement(LogoContent.type, {
                                ...LogoContent.props,
                                className: `logo ${LogoContent.props.className ?? ''}`
                            }, getLogo())}
                        </div> :
                    <div>
                        <a href="/" className="logo">
                            {getLogo()}
                        </a>
                    </div>
                }

                {/* Links */}
                <ul className="nav-menu flex align-c just-sb ls-no g20">
                    {getLinks()}
                </ul>

                {/* Menu/Times */}
                {data.length > 0 ?
                    isCollapse ?
                        <svg className="icon hamburger" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" onClick={() => { setIsCollapse(!isCollapse) }}>
                            <path d="M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z"></path>
                        </svg> :
                        <svg className="icon hamburger" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" onClick={() => { setIsCollapse(!isCollapse) }}>
                            <path fillRule="evenodd" clipRule="evenodd" d="M7.116 8l-4.558 4.558.884.884L8 8.884l4.558 4.558.884-.884L8.884 8l4.558-4.558-.884-.884L8 7.116 3.442 2.558l-.884.884L7.116 8z"></path>
                        </svg>
                    : <></>}
            </div>

            {/* Menu Mobile */}
            <div className={`navbar-menu ${isCollapse ? 'none' : 'flex'}`}>
                <ul>
                    {getLinks()}
                </ul>
            </div>
        </nav>
    );
}