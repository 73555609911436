import React from "react";

export default function Button({
  children,
  className,
  classChild = "",
  disabled,
  onClick,
}) {
  return (
    <button className={className} disabled={disabled} onClick={onClick}>
      <div className={`button-wrapper ${classChild}`}>{children}</div>
    </button>
  );
}
