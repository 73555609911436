import React, { Fragment, useEffect, useRef, useState } from "react";

export default function Input({ children, type = '', placeholder = '', className = '', onChange = () => { }, id = '', value, showArrow, classContent, autoFocus = false, onClickIcon = () => { }, disabled = false, items = [], onSelectOption = () => { }, idOption = 0, ...props }) {

    const [isVisible, setIsVisible] = useState(false);
    const [data, setData] = useState([]);
    const [filterdata, setFilterData] = useState([]);
    const [inputSearch, setInputSearch] = useState('');
    const [isCollapsed, setIsCollapsed] = useState(true);


    const inputCreate = useRef(null);

    useEffect(() => {
        if (items.length == 0) return;

        setData(items);
        setFilterData(items);
    }, [items]);

    useEffect(() => {
        if (items.length == 0 || idOption == 0) return;

        let a = items.find(item => item.id == idOption);
        setInputSearch(a.value);
    }, [items, idOption]);

    const handleInput = (event) => {
        let { value } = event.target;
        let a = [...data];

        a = a.filter(item => item.value.toUpperCase().includes(value.toUpperCase()));

        setIsCollapsed(!value);
        setInputSearch(value);
        setFilterData(a);
        onChange(event);
    }

    const handleOption = item => {
        setInputSearch(item.value);
        setIsCollapsed(true);
        onSelectOption(item);
    }

    switch (type) {
        case "search":
            return (
                <>
                    <div className="control">
                        <input id={id} onChange={onChange} className={`input ${className !== undefined ? className : ""}`} type="text" placeholder={placeholder || "Buscar..."} autoFocus={autoFocus} disabled={disabled} />
                        <span className="icon">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" className="icon-search" role="img" viewBox="0 0 512 512">
                                <path fill="currentColor" d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"></path>
                            </svg>
                        </span>
                    </div>
                </>
            );

        case "create":
            return (
                <div className="input-create bt">
                    <input ref={inputCreate} id={id} onChange={onChange} className={`input ${className !== undefined ? className : ""}`} type="text" placeholder={placeholder !== undefined ? placeholder : "Agregar"} autoFocus={autoFocus} disabled={disabled} />
                    <span className="icon" onClick={() => onClickIcon(inputCreate.current, inputCreate)}>
                        <svg viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4z"></path>
                            <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
                        </svg>
                    </span>
                </div>
            );

        //return <input id={id} onChange={onChange} className={`search ${className !== undefined ? className : ''}`} type="text" placeholder={placeholder !== undefined ? placeholder : 'Buscar...'}  /> //icon search

        case "number":
            return <input id={id} onChange={onChange} className={showArrow ? "number " : "number-hidden-spinner " + ` ${className !== undefined ? className : ""}`} type="number" placeholder={placeholder !== undefined ? placeholder : "0"} value={value} autoFocus={autoFocus} disabled={disabled} {...props} />; //icon search
        case "date":
            return <input id={id} onChange={onChange} className={`date ${className !== undefined ? className : ""}`} type="date" placeholder={placeholder !== undefined ? placeholder : "0"} value={value !== undefined ? value : ""} autoFocus={autoFocus} disabled={disabled} {...props} />;

        case "time":
            return <input id={id} onChange={onChange} className={`time ${className !== undefined ? className : ""}`} type="time" placeholder={placeholder !== undefined ? placeholder : "0"} value={value !== undefined ? value : ""} autoFocus={autoFocus} disabled={disabled} {...props} />;

        case "datetime-local":
            return <input id={id} onChange={onChange} className={`datetime-local ${className !== undefined ? className : ""}`} type="datetime-local" placeholder={placeholder !== undefined ? placeholder : "0"} value={value !== undefined ? value : ""} autoFocus={autoFocus} disabled={disabled} {...props} />;

        case "text":
            return <input id={id} onChange={onChange} className={`input text ${className ?? ""}`} type={type} placeholder={placeholder ?? null} value={value ?? null} autoFocus={autoFocus} disabled={disabled} {...props} />;

        case "password":
            return (
                <div className={`password ${classContent}`}>
                    <input id={id} onChange={onChange} className={`${className ?? ""}`} type={isVisible ? "text" : "password"} placeholder={placeholder ?? null} value={value ?? null} autoFocus={autoFocus} disabled={disabled} {...props}/>
                    {isVisible ? (
                        <svg className="password-hide" viewBox="0 0 512 512" onClick={() => setIsVisible(!isVisible)}>
                            <path d="M432 448a15.92 15.92 0 01-11.31-4.69l-352-352a16 16 0 0122.62-22.62l352 352A16 16 0 01432 448zm-176.34-64c-41.49 0-81.5-12.28-118.92-36.5-34.07-22-64.74-53.51-88.7-91v-.08c19.94-28.57 41.78-52.73 65.24-72.21a2 2 0 00.14-2.94L93.5 161.38a2 2 0 00-2.71-.12c-24.92 21-48.05 46.76-69.08 76.92a31.92 31.92 0 00-.64 35.54c26.41 41.33 60.4 76.14 98.28 100.65C162 402 207.9 416 255.66 416a239.13 239.13 0 0075.8-12.58 2 2 0 00.77-3.31l-21.58-21.58a4 4 0 00-3.83-1 204.8 204.8 0 01-51.16 6.47zm235.18-145.4c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.66 96a227.34 227.34 0 00-74.89 12.83 2 2 0 00-.75 3.31l21.55 21.55a4 4 0 003.88 1 192.82 192.82 0 0150.21-6.69c40.69 0 80.58 12.43 118.55 37 34.71 22.4 65.74 53.88 89.76 91a.13.13 0 010 .16 310.72 310.72 0 01-64.12 72.73 2 2 0 00-.15 2.95l19.9 19.89a2 2 0 002.7.13 343.49 343.49 0 0068.64-78.48 32.2 32.2 0 00-.1-34.78z"></path>
                            <path d="M256 160a95.88 95.88 0 00-21.37 2.4 2 2 0 00-1 3.38l112.59 112.56a2 2 0 003.38-1A96 96 0 00256 160zm-90.22 73.66a2 2 0 00-3.38 1 96 96 0 00115 115 2 2 0 001-3.38z"></path>
                        </svg>
                    ) : (
                        <svg className="password-show" viewBox="0 0 512 512" onClick={() => setIsVisible(!isVisible)}>
                            <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 00-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 000-17.47C428.89 172.28 347.8 112 255.66 112z"></path>
                            <circle cx="256" cy="256" r="80" fill="none" strokeMiterlimit="10" strokeWidth="32"></circle>
                        </svg>
                    )}
                </div>
            );

        case "autocomplete":
            return (
                <>
                    <div className={`close-content-list ${isCollapsed && 'none'}`} onClick={() => setIsCollapsed(true)}></div>
                    <div className={`content-autocomplete w100 ${!isCollapsed && 'z-index-100'}`}>
                        <div className="control">
                            <input id={id} onChange={handleInput} value={inputSearch} className={`input ${className}`} type="text" placeholder={placeholder || 'Buscar...'} disabled={disabled} {...props} />
                            <span className="icon">
                                <svg viewBox="0 0 512 512">
                                    <path d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z"></path>
                                </svg>
                            </span>
                        </div>
                        <div className={`content-list ${isCollapsed && 'none'}`}>
                            {filterdata.map((item, index) => {
                                return (
                                    <div className="item" data-id={item.id} key={`autocomplete-${item.id}`} aria-selected={true} onClick={() => handleOption(item)}>
                                        <span>{item.value}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </>
            );

        default:
            return <input type="text" autoFocus={autoFocus} disabled={disabled} {...props} />;
    }
}
