export default function iconBattery() {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        d="M336.949 83.675c.35.002.697.002 1.048 0h1.048c.455.011.9.029 1.339.059 7.456.473 13.878 3.421 19.262 8.844 5.953 5.948 8.94 13.125 8.963 21.53v332.837c-.005.471-.025.937-.059 1.396-.321 7.806-3.287 14.518-8.904 20.134-5.577 5.644-12.249 8.633-20.019 8.96-.443.036-.889.055-1.339.06H173.654a15.428 15.428 0 01-1.222-.06c-7.817-.315-14.528-3.303-20.136-8.96-5.936-5.946-8.903-13.124-8.904-21.529V114.108c.001-8.405 2.969-15.582 8.904-21.53 5.889-5.935 13.008-8.901 21.357-8.903v.059c86.639-.03 140.374-.05 161.201-.059.7.005 1.397.005 2.095 0zM225.972 408.018v20.714h60.116v-20.714h-60.116zm-19.961-110.21h39.514l-17.982 62.262a5.304 5.304 0 00.233 3.667 5.294 5.294 0 002.503 2.677c1.149.609 2.352.784 3.607.523a5.388 5.388 0 003.143-1.921l73.267-91.472c.869-1.116 1.237-2.378 1.107-3.782-.091-1.423-.652-2.625-1.689-3.607-1.057-.993-2.298-1.498-3.724-1.514h-39.513l17.981-62.202a5.647 5.647 0 00-.232-3.782c-.514-1.168-1.346-2.041-2.503-2.618a5.336 5.336 0 00-3.667-.467 5.518 5.518 0 00-3.2 1.921l-73.15 91.472c-.907 1.096-1.315 2.337-1.224 3.724.129 1.44.73 2.663 1.806 3.666 1.055.971 2.297 1.456 3.723 1.453zm60.347-165.312V112.77H245.7v19.727h-19.729v20.714H245.7v19.728h20.658V153.21h19.729v-20.714h-19.729z"
      ></path>
      <path

        d="M202.984 33.808H309.597V66.50999999999999H202.984z"
 
      ></path>
    </svg>    );
}
