import React, { useState, Fragment, createElement } from "react";

export default function Preloader({ size=40, color='#71D44C' }) {

    return (
        <div className='content-spinner'>
            <svg class="spinner" viewBox="0 0 50 50" width={`${size}px`} height={`${size}px`}>
                <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5" stroke={color}></circle>
            </svg>
        </div>
    );
}