import React, {Fragment} from "react";

export default function Breadcrumb({children}) {
    return (
        <div className="breadcrumb">
            {children.map((child, index) => {
                return (
                    <>
                        {index > 0 && (
                            <div className="arrow">
                                <svg viewBox="0 0 86.6 75">
                                    <polygon points="0.2,74.9 43.3,0.2 86.4,74.9" />
                                    <path d="M43.3,0.5l42.9,74.2H0.4L43.3,0.5 M43.3,0L0,75h86.6L43.3,0L43.3,0z" />
                                </svg>
                            </div>
                        )}
                        <div key={index}>{child}</div>
                    </>
                );
            })}
        </div>
    );
}
