import React from "react";
// Layout
import Main from "../../../components/layout/Main";

// Component
import BoxCode from "../../../components/elements/BoxCode";
import Button from "../../../components/elements/Button";
import RadioLabel from "../../../components/elements/RadioLabel";

export default function Botones() {

    const code1 = `
import Button from "../../../components/elements/Button";

export default function BotonesExample() {
    return (
        <>
                        <Button>Default</Button>
                        <Button className="important">Important</Button>
                        <Button className="primary">Primary</Button>
                        <Button className="caution">Caution</Button>
                </>
    );
}`;

    const code2 = `
import Button from "../../../components/elements/Button";

export default function BotonesExample() {
    return (
        <>
                        <Button className="hover">Default</Button>
                        <Button className="hover important">Important</Button>
                        <Button className="hover primary">Primary</Button>
                        <Button className="hover caution">Caution</Button>
                </>
    );
}`;

    const code3 = `
import Button from "../../../components/elements/Button";

export default function BotonesExample() {
    return (
        <>
                        <Button className="active">Default</Button>
                        <Button className="active important">Important</Button>
                        <Button className="active primary">Primary</Button>
                        <Button className="active caution">Caution</Button>
                </>
    );
}`;

    const code4 = `
import Button from "../../../components/elements/Button";

export default function BotonesExample() {
    return (
        <>
                    <Button disabled={true}>Default</Button>
                    <Button disabled={true} className="important">Important</Button>
                    <Button disabled={true} className="primary">Primary</Button>
                    <Button disabled={true} className="caution">Caution</Button>
                </>
    );
}`;

    const code5 = `
import Button from "../../../components/elements/Button";

export default function BotonesExample() {
    return (
        <>
                    <Button disabled={true} className="loading ">Default</Button>
                    <Button disabled={true} className="loading important">Important</Button>
                    <Button disabled={true} className="loading primary">Primary</Button>
                    <Button disabled={true} className="loading caution">Caution</Button>
                </>
    );
}`;

    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Botones' code={code1}>
                    <Button>Default</Button>
                    <Button className="important">Important</Button>
                    <Button className="primary">Primary</Button>
                    <Button className="caution">Caution</Button>
                </BoxCode>


                <BoxCode title='Botones :hover' code={code2}>
                    <Button className="hover">Default</Button>
                    <Button className="hover important">Important</Button>
                    <Button className="hover primary">Primary</Button>
                    <Button className="hover caution">Caution</Button>
                </BoxCode>


                <BoxCode title='Botones :active' code={code3}>
                    <Button className="active">Default</Button>
                    <Button className="active important">Important</Button>
                    <Button className="active primary">Primary</Button>
                    <Button className="active caution">Caution</Button>
                </BoxCode>


                <BoxCode title='Botones Disabled' code={code4}>
                    <Button disabled={true}>Default</Button>
                    <Button disabled={true} className="important">Important</Button>
                    <Button disabled={true} className="primary">Primary</Button>
                    <Button disabled={true} className="caution">Caution</Button>
                </BoxCode>


                <BoxCode title='Botones Disabled + Loading' code={code5}>
                    <Button disabled={true} className="loading ">Default</Button>
                    <Button disabled={true} className="loading important">Important</Button>
                    <Button disabled={true} className="loading primary">Primary</Button>
                    <Button disabled={true} className="loading caution">Caution</Button>
                </BoxCode>
            </div>
        </Main>
    );
}
