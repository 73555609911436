
import React, { useState } from "react";

import { PreviewCode } from "./PreviewCode";
import Switch from "./Switch";

export default function BoxCode({ children, title, classBox, code }) {

    const [isCode, setIsCode] = useState(false);
    const [isCopy, setIsCopy] = useState(false);

    async function codeCopy() {
        try {
            await navigator.clipboard.writeText(code);
            handleCopy()
        } catch (err) {console.error('Failed to copy: ', err)}
        
    }

    function handleCopy() {
        setIsCopy(true);
        setTimeout(() => {
            setIsCopy(false);
        }, 2000);
    }

    return (
        <>
            <div className="flex align-s just-sb g15">
                <h3>{title}</h3>
                <Switch type='code' onChange={() => setIsCode(!isCode)} />
            </div>


            <div className={`box g20 flex ${classBox != undefined ? classBox : ''}`}>
                {!isCode ? children :
                <div className="code-content">
                    <div className={`copy ${isCopy ? 'active' : ''}`} onClick={codeCopy}>
                        {!isCopy ?
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" aria-hidden="true" viewBox="0 0 24 24" strokeWidth="2" height="1rem" width="1rem">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"></path>
                        </svg> :
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"></path>
                        </svg>}
                    </div>
                    <PreviewCode>{code}</PreviewCode>
                </div>}
            </div>
        </>
    );
}