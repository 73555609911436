import BoxCode from "../../../components/elements/BoxCode";
import StaticSidebar from "../../../components/elements/StaticSidebar";
import Main from "../../../components/layout/Main";
import { Link, useLocation } from "react-router-dom";

export default function StaticSidebarExample() {

    const data = [{
        text: 'Dashboard',
        link: <Link to='/'></Link>,
        active: '/componentes',
        icon: <svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48h-48z" fill="none"/><path d="M6 26h16v-20h-16v20zm0 16h16v-12h-16v12zm20 0h16v-20h-16v20zm0-36v12h16v-12h-16z"/></svg>,
        iconHeight: 15,
        iconWidth: 15,
        iconContent: ''
    },
    {
        text: 'Ciudades',
        link: '',
        active: '',
        icon: '',
        iconContent: ''
    },
    {
        text: 'Sistema',
        classContent: 'disabled',
        active: '',
        icon: '',
        iconContent: ''
    },
    {
        text: 'Subsistema',
        classContent: 'disabled',
        active: '',
        icon: '',
        iconContent: ''
    },
    {
        text: 'Ramales',
        classContent: 'disabled',
        active: '',
        icon: '',
        iconContent: ''
    },
    {
        text: 'Infraestructura',
        classContent: 'disabled',
        active: '',
        icon: '',
        iconContent: ''
    },
    {
        text: 'Gabinetes',
        classContent: 'disabled',
        active: '',
        icon: '',
        iconContent: ''
    }]

    const {pathname} = useLocation();

    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Static Sidebar'>
                    <StaticSidebar data={data} pathname={pathname} />
                    <Link></Link>

                    {/* <StaticSidebar sidebarWidth='165'>
                        
                        <li className="active">
                            <Link href="/home"><Image src={Dashboard} alt="Dashboard" width={20} height={20} />Dashboard</Link>
                        </li>
                        <li>
                            <Link href="/home"><Image src={Ciudades} alt="Ciudades" width={20} height={20} />Ciudades</Link>
                        </li>
                        
                        <li>
                        <Link href="/home"><Image src={Sistema} width={20} height={20} alt="Sistema" />Sistema</Link>
                        </li> 
                        <li><Link href="/home"><Image src={SubSistema} width={20} height={20} alt="Subsistema" />Subsistema</Link></li>
                        <li><Link href="/home"><Image src={Ramales} width={20} height={20} alt="Ramales" />Ramales</Link></li>
                        <li><Link href="/home"><Image src={Infraestructura} width={20} height={20} alt="Infraestructura" />Infraestructura</Link></li>
                        <li><Link href="/home"><Image src={Gabinetes} width={20} height={20} alt="Gabinetes" />Gabinetes</Link></li>
                    </StaticSidebar> */}

                    {/* <StaticSidebar>
                        <li className="active">
                            <a href="#">Dash</a>
                        </li>
                        <li>
                            <a href="#">Ciudades</a>
                        </li>
                        <li>
                            <a href="#">Sistema</a>
                        </li>
                        <li>
                            <a href="#">subsistema</a>
                        </li>
                        <li className="danger">
                            <a href="#">ramales</a>
                        </li>
                    </StaticSidebar> */}
                </BoxCode>
            </div>
        </Main>
    );
}