import React ,{useState} from 'react';
import Tabs from '../../../components/elements/Tabs';
import Main from "../../../components/layout/Main";
import Tab from '../../../components/elements/Tab';
import TabContent from '../../../components/elements/TabContent';


export default function TabsExample() {

    const [isActive, setisActive] = useState('');

    const handleClick = (nombre) => {
        setisActive(nombre);
    }

    const onClose = (nombre) => {
       console.log('Cerrando',nombre);
    }

    return (
    <Main>
        <div className="section elementos">
            <h5 className="c-green">Componentes</h5>
            <h2>Elementos</h2>
            <Tabs>
                <Tab nombre={'Hola'} onClick={ () => handleClick('Hola') } isActive={isActive} onClose={()=>onClose('Hola')}/>
                <Tab nombre={'Hola2'} onClick={ () => handleClick('Hola2') } isActive={isActive}/>
                <Tab nombre={'Hola3'} onClick={ () => handleClick('Hola3') } isActive={isActive}/>
                
                <TabContent nombre={'Hola'} isActive={isActive} >
                    <p>Contenido de la pestaña 1</p>
                </TabContent>
                <TabContent nombre={'Hola2'} isActive={isActive} >
                    <p>Contenido de la pestaña 2</p>
                </TabContent>
                <TabContent nombre={'Hola3'} isActive={isActive} >
                    <p>Contenido de la pestaña 3</p>
                </TabContent>
            </Tabs> 
        </div>
    </Main>);
}