
import { Navigate, Route, Routes } from "react-router-dom";

import DnDExample from "../pages/DnD";
import OpcionMultipleExample from "../pages/OpcionMultiple";
import OpcionSimpleExample from "../pages/OpcionSimple";
import PuntuacionExample from "../pages/Puntuacion";

export default function CampanasRoutes() {
  return (
    <Routes>
      <Route path="/opcion-simple" element={<OpcionSimpleExample />} />
      <Route path="/opcion-multiple" element={<OpcionMultipleExample />} />
      <Route path="/drag-and-drop" element={<DnDExample />} />
      <Route path="/puntuacion" element={<PuntuacionExample />} />
      <Route path="/" element={<Navigate to={"opcion-simple"} />} />
    </Routes>
  );
}