import React from "react";

import Main from "../../../components/layout/Main";

export default function Tipografia() {
    return (
        <Main>
            <div className="section typography">
                <h5 className="c-green">Fundaciones</h5>
                <h2>Tipografía</h2>

                <div className="sampler">
                    <h1>Esto es Gotham Narrow, la tipografía de la marca.</h1>
                    <p>Gotham celebra las letras atractivas y sin pretensiones de la ciudad. Nueva York está llena de este tipo de letras, sans serif hechas a mano que comparten una estructura subyacente común, la idea de un ingeniero de "letras básicas" que trasciende tanto las características de sus materiales como los gestos de sus creadores.</p>

                    <h3 className="typo2">Inter es la tipografía de soporte.</h3>
                    <p>Inter is a variable font family carefully crafted & designed for computer screens. Inter features a tall x-height to aid in readability of mixed-case and lower-case text. Several OpenType features are provided as well, like contextual alternates that adjusts punctuation depending on the shape of surrounding glyphs, slashed zero for when you need to disambiguate "0" from "o", tabular numbers, etc. The Inter project is led by Rasmus Andersson, a Swedish maker–of–software living in San Francisco. To contribute, see github.com/rsms/inter</p>
                </div>

                <ul className="flex col mt40 typo-list">
                    <li className="flex row g10 align-c just-sb">
                        <h1>Heading One</h1>
                        <p>44px Gotham Narrow | Black | -1px k</p>
                    </li>
                    <li className="flex row g10 align-c just-sb">
                        <h2>Heading Two</h2>
                        <p>36px Gotham Narrow | Bold | -1px k</p>
                    </li>
                    <li className="flex row g10 align-c just-sb">
                        <h3>Heading Three</h3>
                        <p>28px Inter | Bold | -1px k</p>
                    </li>
                    <li className="flex row g10 align-c just-sb">
                        <h4>Heading Four</h4>
                        <p>18px Inter | Medium | -0.5px k</p>
                    </li>
                    <li className="flex row g10 align-c just-sb">
                        <h5>Heading Five</h5>
                        <p>16px Inter | Bold | 0 k</p>
                    </li>
                    <li className="flex row g10 align-c just-sb">
                        <p>
                            <b>Body Bold</b>
                        </p>
                        <p>16px Inter | Bold</p>
                    </li>
                    <li className="flex row g10 align-c just-sb">
                        <p>Body</p>
                        <p>16px Inter | Regular</p>
                    </li>
                    <li className="flex row g10 align-c just-sb">
                        <p className="button" style={{background: "transparent", height: "auto"}}>
                            Button & Meta
                        </p>
                        <p>16px Inter | Medium</p>
                    </li>
                </ul>
            </div>
        </Main>
    );
}
