import React from "react";
// Layout
import Main from "../../../components/layout/Main";

// Component
import Sidebar from "../../../components/elements/Sidebar";
import Tag from "../../../components/elements/Tag";
import BoxCode from "../../../components/elements/BoxCode";
import Menu from '../../../assets/imgs/menu.svg';
import { useState } from "react";

export default function SidebarExample() {

    const [isOpen, setIsOpen] = useState(false);

    const code =  `
    import Sidebar from "../../../components/elements/Sidebar";
    import Tag from "../../../components/elements/Tag";

    export default function SidebarExample() {
        return (
            <Sidebar title={"Switch 92-20-42-75-6C-D7"} save={"Save"} cancel={"Cancel"}>
                <div className="section flex g20">
                    <Tag>León</Tag>
                    <Tag>Línea 1</Tag>
                    <Tag>Camión 32</Tag>
                </div>
                <div className="section">
                    <img src="https://i.ibb.co/4fsM9jj/image.png" alt="imagen" width="70%"/>
                    <h5>MAC Address:</h5><p>92-20-42-75-6C-D7</p>
                    <h5>IP Address:</h5><p>10.0.0.1</p>
                </div>
            </Sidebar>
        );
    }`;

    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Sidebar' classBox='gray6' code={code}>
                    <div>
                        {/* <span style={{fontSize:30,cursor:"pointer"}} onClick={() => setOpen(!open)}> */}
                        <span style={{fontSize:30,cursor:"pointer"}} onClick={() => setIsOpen(!isOpen)}>
                            <img src={Menu} alt="Menu" width='30px' height='30px' />
                        </span>
                    </div>

                    <Sidebar open={isOpen} setOpen={setIsOpen} title={"Switch 92-20-42-75-6C-D7"} save={"Save"} cancel={"Cancel"}>
                        <div className="section flex g20">
                            <Tag>León</Tag>
                            <Tag>Línea 1</Tag>
                            <Tag>Camión 32</Tag>
                        </div>
                        <div className="section">
                            <img src="https://i.ibb.co/4fsM9jj/image.png" alt="imagen" width="70%"/>
                            <h5>MAC Address:</h5><p>92-20-42-75-6C-D7</p>
                            <h5>IP Address:</h5><p>10.0.0.1</p>
                        </div>
                    </Sidebar>
                </BoxCode>
            </div>
        </Main>
    );
}