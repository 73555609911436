import React, { useEffect, useState, Fragment, createElement } from "react";
import { useRef } from "react";
import Dropdown from "../Dropdown";
import Col from "./Col";
import Row from "./Row";
import Preloader from "../Preloader";

export default function Table({
  children,
  className,
  pag,
  gap=20,
  minCol=1,
  empty,
  isResponsive=false,
  classContent=''
}) {
  const contenTable = useRef(null);
  const table = useRef(null);

  const header = Array.isArray(children) ? children[0] : children;
  const filterActive = [];

  const [body, setBody] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  const [order, setOrder] = useState(0);
  const [filterIndex, setFilterIndex] = useState(0);

  const [filtros, setFiltros] = useState([]);

  const [indexPag, setIndexPag] = useState(1);
  const [pagMax, setPagMax] = useState(pag);
  const [pagMin, setPagMin] = useState(0);

  const [arrayWidth, setArrayWidth] = useState([]);
  const [column, setColumn] = useState(0);

  const [isMobile, setIsMobile] = useState(false);
  const [columns, setColumns] = useState(0);
  
  const [maxWidth, setMaxWidth] = useState(0);

  
  useEffect(() => {
    setMaxWidth(minCol);
  }, [minCol])

  useEffect(() => {
    if (Array.isArray(children[1])) {
      setBody(children[1]);
    } else {
      setBody(
        Array.isArray(children)
          ? children.filter(
              (child) => child.props.type?.toUpperCase() != "HEADER"
            )
          : []
      );
    }
  }, [children]);

  useEffect(() => {
    obtenerHeader(header);
    if (tableBody.length > 0) {
      table.current.firstChild.childNodes.forEach((el) => {
        setArrayWidth((arrayWidth) => [...arrayWidth, el.clientWidth]);
      });
    }
  }, [tableBody]);

  useEffect(() => {
    setTableBody(obtenerBody(body, header));
    if (body.length > 0) {
      obtenerFiltros(header, body);
    }
  }, [body, isMobile]);

  useEffect(() => {
    // ESCUCHAMOS EL EVENTO DE REZISE DEL CONTENEDOR DE LA TABLA
    if (maxWidth == 0) return
    new ResizeObserver(([e]) => resizeTable(e.target)).observe(contenTable.current);
  }, [maxWidth]);

  const resizeTable = ({ clientWidth, scrollHeight, clientHeight }) => {
    setColumns(Math.floor(clientWidth / ((maxWidth + gap) + (scrollHeight > clientHeight ? 10 : 0))));

    clientWidth < 300 ? setIsMobile(true) : setIsMobile(false);
  }
  

  useEffect(() => {
    if (columns == 0) return

    contenTable.current.querySelectorAll('th').forEach(th => th.classList.remove('none'));
    contenTable.current.querySelectorAll('td').forEach(td => td.classList.remove('none'));
    let col = contenTable.current.querySelectorAll('.table-header .td:not(.none)');

    if (col.length > columns || !isResponsive) {
      do {

        if (col.length == contenTable.current.querySelectorAll('.table-header .high').length) return

        let header = contenTable.current.querySelector('.table-header');
        let body = contenTable.current.querySelectorAll('.table-row:not(.table-header)');
  
        let noneLow = [...header.querySelectorAll('.low:not(.none)')].at(-1);
        let noneMedium = [...header.querySelectorAll('.medium:not(.none)')].at(-1);
  
        noneLow ? noneLow.classList.add('none') : noneMedium && noneMedium.classList.add('none');
  
        body.forEach(tr => {
          let noneLow = [...tr.querySelectorAll('.low:not(.none)')].at(-1);
          let noneMedium = [...tr.querySelectorAll('.medium:not(.none)')].at(-1);
  
          noneLow ? noneLow.classList.add('none') : noneMedium && noneMedium.classList.add('none');
        });
  
        col = contenTable.current.querySelectorAll('.table-header .td:not(.none)');
  
      } while (col.length > columns);
    }

    let trHeader = contenTable.current.querySelectorAll('.table-header .td:not(.none)');
    let trs = contenTable.current.querySelectorAll('tr');
    let gridColumns = '';

    trHeader.forEach((item, index) => {
      // gridColumns += `${item.attributes.size ? `${item.attributes.size.value}px ` : `minmax(${minCol}px, 1fr)` }`
      gridColumns += `minmax(${item.attributes.size ? item.attributes.size.value : maxWidth}px, ${item.attributes.maxSize.value != 0 ? `${item.attributes.maxSize.value}px` : '1fr'}) `;
      // gridColumns += index == 0 ? `1fr ` : `minmax(${maxWidth}px, 1fr) `;
    })
    trs.forEach(tr => tr.style.gridTemplateColumns = gridColumns);

    if (maxWidth == 1) {
      let arr = [];
      trHeader.forEach((item, index) => {
        arr.push(item.attributes.size ? item.attributes.size.value : item.clientWidth);
      })

      // setMaxWidth(Math.max(...arr) - Math.min(...arr));
    //   if (minCol == 0) {
    //     // setMaxWidth(Math.max(...arr));
      setMaxWidth(((Math.max(...arr) + Math.min(...arr))/2));
    //     // setMaxWidth(Math.max(...arr));
    //   }

    //   // console.log(arr);
    //   // let arr2 = arr.filter(item => item == Math.max(...arr));
    //   // console.log(arr2);
    //   // console.log(Math.max.apply(null, arr));
    //   // console.log(Math.max(...arr), Math.min(...arr), );

    //   // setMaxWidth((((Math.max(...arr) - Math.min(...arr))/2) + Math.min(...arr)));
    //   // setMaxWidth(contenTable.current.querySelector('.table-header .td').clientWidth);
    }

    table.current.style.setProperty(`--gap`, typeof gap == "string" ? gap : `${gap}px`);
  }, [tableBody, columns]);

  // function resizeTable(contentWidth) {

  //     console.log(contentWidth, table.current);

  //     // if (table.current?.scrollWidth > contentWidth) {
  //     //     let nodos = document.querySelectorAll(".table-header td.priority:not(.none)");

  //     //     if (nodos.length == 0) {
  //     //         nodos = document.querySelectorAll(".table-header td.priority-baja:not(.none)");
  //     //     }
  //     //     if (nodos.length == 0) {
  //     //         nodos = document.querySelectorAll(".table-header td.priority-media:not(.none)");
  //     //     }

  //     //     if (nodos.length > 0) {
  //     //         document.querySelectorAll(`.${nodos[nodos.length-1].classList[0]}`).forEach(nodo => nodo.classList.add('none'));
  //     //         // setArrayWidth(arrayWidth);
  //     //     }
  //     // }
  // }

  // function resizeTable(contentWidth) {
  //   if (table.current?.clientWidth > contentWidth) {
  //     let nodos = document.querySelectorAll(
  //       ".table-header td.priority:not(.none)"
  //     );

  //     if (nodos.length == 0) {
  //       nodos = document.querySelectorAll(
  //         ".table-header td.priority-baja:not(.none)"
  //       );
  //     }
  //     if (nodos.length == 0) {
  //       nodos = document.querySelectorAll(
  //         ".table-header td.priority-media:not(.none)"
  //       );
  //     }

  //     if (nodos.length > 0) {
  //       document
  //         .querySelectorAll(`.${nodos[nodos.length - 1].classList[0]}`)
  //         .forEach((nodo) => {
  //           nodo.classList.add("none");
  //         });
  //       setArrayWidth(arrayWidth);
  //     }
  //   }

    // if (arrayWidth[document.querySelectorAll("#table-header td:not(.none)").length] != undefined) {
    //     if (contentWidth > arrayWidth.slice(0, document.querySelectorAll("#table-header td:not(.none)").length + 1).reduce((a, b) => a + b, 10)) {
    //         let nodos = document.querySelectorAll(".table-header td.priority.none");

    //         if (nodos.length == 0) {
    //             nodos = document.querySelectorAll(".table-header td.priority-baja.none");
    //         }
    //         if (nodos.length == 0) {
    //             nodos = document.querySelectorAll(".table-header td.priority-media.none");
    //         }

    //         if (nodos.length > 0) {
    //             document.querySelectorAll(`.${nodos[0].classList[0]}`).forEach(nodo => nodo.classList.remove('none'));
    //             setArrayWidth(arrayWidth);
    //         }
    //     }
    // }
  // }

  function obtenerHeader(header) {
    // let col = ` `;

    // if (table.current != undefined) {
    //   header.props.children.forEach((el, idx) => {
    //     if (el.props.not != true) {
    //       col += `${
    //         el.props.size != undefined
    //           ? typeof el.props.size == "string"
    //             ? el.props.size
    //             : `${el.props.size}px`
    //           : `minmax(var(--min-col), ${
    //               100 / header.props.children.length - 1 / 100
    //             }fr)`
    //       } `;
    //     }
    //   });
    //   table.current.style.setProperty(`--columnas`, col);
    //   table.current.style.setProperty(
    //     `--gap`,
    //     gap != undefined ? (typeof gap == "string" ? gap : `${gap}px`) : ""
    //   );
    //   table.current.style.setProperty(
    //     `--min-col`,
    //     minCol != undefined
    //       ? typeof minCol == "string"
    //         ? minCol
    //         : `${minCol}px`
    //       : ""
    //   );
    //   // if (document.querySelector('#table-wui') != undefined) {
    //   //     header.props.children.forEach((el, idx) => {
    //   //         col += `${el.props.size != undefined ? typeof el.props.size == 'string' ? el.props.size : `${el.props.size}px` : `minmax(var(--min-col), ${100/header.props.children.length - 1/100}fr)`} `
    //   //     });
    //   //     document.querySelector('#table-wui').style.setProperty(`--columnas`, col);
    //   //     document.querySelector('#table-wui').style.setProperty(`--gap`, gap != undefined ? typeof gap == 'string' ? gap : `${gap}px` : '');
    //   //     document.querySelector('#table-wui').style.setProperty(`--min-col`, minCol != undefined ? typeof minCol == 'string' ? minCol : `${minCol}px` : '');
    // }

    setTableHeader(
      // Creamos row
      createElement(
        header.type,
        {
          ...header.props,
          // gap: gap != undefined ? typeof gap == 'string' ? gap : `${gap}px` : '',
          style: header.props.children.length,
        },
        header.props.children.map((el, index) => {
          // creamos col
          if (el.props.not != true) {
            return createElement(el.type, {
              ...el.props,
              className: `${el.props.hide || 'low'} ${el.props.className ?? ""}`,
              actionOrder: () => handleOrder(index),
            });
          }
        })
      )
    );
  }

  // let priorityUndefined = 0;
  // function getClass(priority) {
  //   switch (priority) {
  //     case "baja":
  //       return `priority-baja-${++priorityUndefined} priority-baja`;
  //     case "media":
  //       return `priority-media-${++priorityUndefined} priority-media`;
  //     case "alta":
  //       return `priority-alta-${++priorityUndefined} priority-alta`;

  //     default:
  //       return `priority-${++priorityUndefined} priority`;
  //   }
  // }

  function obtenerBody(body, header) {
    // if (body.length > 0) {
    return body.map((row, index) => {
      let data = [];
      let head = [];
      let type = [];

      row.props.children.forEach((el, idx) => {
        data.push(el.props.children);
        head.push(header.props.children[idx]?.props.children);
        type.push(header.props.children[idx]?.props.type);
      });

      return (
        <Row
          type="body"
          isMobile={isMobile}
          key={index}
          head={head}
          data={data}
          className={`${
            row.props.onClick != undefined ? "cursor-pointer" : ""
          } ${row.props.className ?? ""}`}
          {...row.props}
        >
          {row.props.children.map((el, idx) => {
            if (header.props.children[idx]?.props.not != true) {
              return (
                <Col
                  {...el.props}
                  key={idx}
                  type={type[idx]}
                  priority={`${header.props.children[idx]?.props.priority}`}
                  className={`${header.props.children[idx]?.props.hide || 'low'} ${el.props.className}`}
                  max={header.props.children[idx]?.props.max}
                  size={header.props.children[idx]?.props.size}
                  textAlign={header.props.children[idx]?.props.textAlign}
                >
                  {Array.isArray(el.props.children) ||
                  typeof el.props.children == "string" ||
                  typeof el.props.children == "number"
                    ? el.props.children
                    : createElement(el.props.children.type, {
                        ...el.props.children.props,
                      })}
                </Col>
              ); //Array.isArray(el.props.children) ? el.props.children : createElement(el.props.children.props.type ?? el.props.children.props.type.name, {...el.props.children.props})
            }
          })}
        </Row>
      );
    });
    // } else {
    //     return (
    //         <Row>
    //             <Col className="empty-state" colSpan={header.props.children.length}>
    //                 No se encontraron resultados
    //             </Col>
    //         </Row>
    //     );
    // }
  }

  function obtenerFiltros(header, body) {
    setFiltros([]);
    header.props.children.forEach((el, index) => {
      let [isFilter, type, placeholder, idFilter, onFilter] =
        el.props.filter != undefined ? el.props.filter : [];

      if (isFilter) {
        if (type == "text") {
          setFiltros((filtros) => [
            ...filtros,
            <div className="filter-box">
              <label className="filter-label" htmlFor="">
                {placeholder || el.props.children}
              </label>
              <input
                type="text"
                placeholder={placeholder}
                onChange={({ target }) => handleFilter(target.value, index)}
              />
            </div>,
          ]);
        } else {
          let data = [];

          data.push({ value: "Todos", id: 0 });
          body.forEach((el, idx) => {
            if (el.props.children[index].props.children.type == undefined) {
              if (
                !!!data.find((d) => {
                  return d.validate == el.props.children[index].props.children;
                })
              ) {
                data.push({
                  value: el.props.children[index].props.children,
                  id: el.props.id != undefined ? el.props.id : ++idx,
                  validate: el.props.children[index].props.children,
                });
              }
            } else {
              if (
                el.props.children[index].props.children.type.name ==
                "ProgressBar"
              ) {
                if (
                  !!!data.find((d) => {
                    return (
                      d.validate ==
                      el.props.children[index].props.children.props.porcentaje
                    );
                  })
                ) {
                  data.push({
                    value: el.props.children[index].props.children,
                    id: el.props.id != undefined ? el.props.id : ++idx,
                    validate:
                      el.props.children[index].props.children.props.porcentaje,
                  });
                  // data.push({ value: el.props.children[index].props.children.props.porcentaje, id: el.props.id != undefined ? el.props.id : ++idx});
                }
              }
            }
          });

          if (idFilter != undefined || idFilter != "") {
            let filterData = data.filter((data) => data.id == idFilter)[0];
            if (filterData != undefined) {
              handleFilter(
                filterData.value,
                index,
                idFilter,
                filterData.validate
              );
            }
          }

          setFiltros((filtros) => [
            ...filtros,
            <div className="filter-box">
              <Dropdown
                titulo={placeholder || el.props.children}
                items={data}
                id={idFilter}
                onSelectOption={({ value, id, validate }) => {
                  handleDrop(value, index, id, validate, onFilter);
                }}
              />
            </div>,
          ]);
        }
      }
    });
  }

  function handleDrop(value, index, id, validate, onFilter) {
    handleFilter(value, index, id, validate);
    if (onFilter != undefined) {
      onFilter(value, index, id, validate);
    }
  }

  function handleOrder(index) {
    let filterBody = [...body];
    let filterOrder = order;

    if (filterOrder != 0 && index != filterIndex) {
      document
        .querySelectorAll(".table-arrow svg")
        .forEach((el) => el.classList.remove("on"));
      filterOrder = 0;
    }

    setFilterIndex(index);

    if (filterBody[0].props.children[index].props.children.type != undefined) {
      if (
        filterBody[0].props.children[index].props.children.type.name ==
        "ProgressBar"
      ) {
        setTableBody(
          obtenerBody(
            filterOrder == 2
              ? filterBody
              : filterBody.sort((a, b) => {
                  if (
                    a.props.children[index].props.children.props.porcentaje <
                    b.props.children[index].props.children.props.porcentaje
                  ) {
                    return filterOrder != 0 ? 1 : -1;
                  }

                  if (
                    a.props.children[index].props.children.props.porcentaje >
                    b.props.children[index].props.children.props.porcentaje
                  ) {
                    return filterOrder != 0 ? -1 : 1;
                  }

                  return 0;
                }),
            header
          )
        );
      }
      if (
        filterBody[0].props.children[index].props.children.type.name == "Tag"
      ) {
        setTableBody(
          obtenerBody(
            filterOrder == 2
              ? filterBody
              : filterBody.sort((a, b) => {
                  if (
                    a.props.children[index].props.children.props.children <
                    b.props.children[index].props.children.props.children
                  ) {
                    return filterOrder != 0 ? 1 : -1;
                  }

                  if (
                    a.props.children[index].props.children.props.children >
                    b.props.children[index].props.children.props.children
                  ) {
                    return filterOrder != 0 ? -1 : 1;
                  }

                  return 0;
                }),
            header
          )
        );
      }
    } else {
      setTableBody(
        obtenerBody(
          filterOrder == 2
            ? filterBody
            : filterBody.sort((a, b) => {
                if (
                  a.props.children[index].props.children <
                  b.props.children[index].props.children
                ) {
                  return filterOrder != 0 ? 1 : -1;
                }

                if (
                  a.props.children[index].props.children >
                  b.props.children[index].props.children
                ) {
                  return filterOrder != 0 ? -1 : 1;
                }

                return 0;
              }),
          header
        )
      );
    }

    setOrder(filterOrder > 1 ? 0 : filterOrder + 1);
  }

  function handleFilter(value, index, id, validate) {
    if (!!filterActive.find((el) => el.index == index)) {
      filterActive[filterActive.findIndex((el) => el.index == index)] = {
        value,
        index,
        id,
        validate,
      };
    } else {
      filterActive.push({ value, index, id, validate });
    }

    if (value == "") {
      setTableBody(obtenerBody(body, header));
    } else {
      let arr = [...body];

      filterActive.forEach((filter) => {
        let a = [];
        arr.forEach((el) => {
          if (
            el.props.children[filter.index].props.children.type != undefined
          ) {
            if (
              el.props.children[filter.index].props.children.type.name ==
              "ProgressBar"
            ) {
              if (
                el.props.children[filter.index].props.children.props
                  .porcentaje == filter.validate ||
                filter.id == 0
              ) {
                a.push(el);
              }
            }
          } else {
            if (
              el.props.children[filter.index].props.children ==
                filter.validate ||
              filter.id == 0
            ) {
              a.push(el);
            }
          }
        });
        arr = a;
      });
      setTableBody(obtenerBody(arr, header));
    }
  }

  function prev() {
    setIndexPag(indexPag - 1);
    setPagMax(pagMax - pag);
    setPagMin(pagMin - pag);
  }

  function next() {
    setIndexPag(indexPag + 1);
    setPagMax(pagMax + pag);
    setPagMin(pagMax);
  }

  function handleItem(index) {
    setIndexPag(index);
    setPagMax(pag * index);
    setPagMin(pag * index - pag);
  }

  function getItem() {
    let items = [];

    for (let i = 1; i <= Math.ceil(tableBody.length / pag); i++) {
      items.push(
        <li
          className={`${indexPag == i ? "active" : ""}`}
          onClick={() => handleItem(i)}
        >
          {i}
        </li>
      );
    }

    return items;
  }

  return (
    <div className={`content-table ${classContent}`} id="content_table" ref={contenTable}>
      {filtros.length > 0 ? <div className="filters">{filtros}</div> : <></>}
      <table
        id="table-wui"
        className={`table ${className !== undefined ? className : ""}`}
        ref={table}
      >
        {tableHeader}
        {/* <tbody id="table-body" className="table-body"> */}
        {Array.isArray(tableBody) ? tableBody.slice(pagMin, pagMax) : tableBody}
        {/* </tbody> */}
      </table>

      {pag != undefined ? (
        <div className="pagination">
          <div
            className={`prev ${indexPag == 1 ? "disabled" : ""}`}
            onClick={() => prev()}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 320 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>
            </svg>
          </div>
          <div className="pag">
            <ul>{getItem()}</ul>
          </div>
          <div
            className={`next ${
              indexPag == Math.ceil(tableBody.length / pag) ? "disabled" : ""
            }`}
            onClick={() => next()}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 320 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
            </svg>
          </div>
        </div>
      ) : (
        <></>
      )}
      {tableBody.length == 0 ? (
        <p className="table-empty">{empty ?? "No se encontraron resultados"}</p>
      ) : (
        <></>
      )}
    </div>
  );
}
