import React from "react";
// Layout
import Main from "../../../components/layout/Main";

// Component
import BoxCode from "../../../components/elements/BoxCode";
import Listing from "../../../components/elements/Listing";
import ListingItem from "../../../components/elements/ListingItem";
import Tag from "../../../components/elements/Tag";


export default function Listado() {

    const code1 = `
import Listing from "../../../components/elements/Listing";
import ListingItem from "../../../components/elements/ListingItem";

export default function ListadoExample() {
    return (
        <Listing>
                        <ListingItem>
                                <span>Item 1</span>
                        </ListingItem>
                        <ListingItem>
                                <span>Item 2</span>
                        </ListingItem>
                        <ListingItem>
                                <span>Item 3</span>
                        </ListingItem>
                </Listing>
    );
}`;

    const code2 = `
import Listing from "../../../components/elements/Listing";
import ListingItem from "../../../components/elements/ListingItem";
import Tag from "../../../components/elements/Tag";

export default function ListadoExample() {
    return (
        <Listing>
                        <ListingItem>
                                <span>Item 1</span>
                                <div className="flex g15">
                                        <Tag>Tag 1</Tag>
                                        <Tag>Tag 2</Tag>
                                        <Tag>Tag 3</Tag>
                                </div>
                        </ListingItem>
                        <ListingItem>
                                <span>Item 2</span>
                                <div className="flex g15">
                                        <Tag>Tag 1</Tag>
                                        <Tag>Tag 2</Tag>
                                </div>
                        </ListingItem>
                        <ListingItem>
                                <span>Item 3</span>
                                <div className="flex g15">
                                        <Tag>Tag 1</Tag>
                                        <Tag>Tag 2</Tag>
                                        <Tag>Tag 3</Tag>
                                        <Tag>Tag 4</Tag>
                                        <Tag>Tag 5</Tag>
                                </div>
                        </ListingItem>
                </Listing>
    );
}`;

    return (
        <Main>
            <div className="section elementos">
                <h5 className="c-green">Componentes</h5>
                <h2>Elementos</h2>

                <BoxCode title='Listado' classBox='gray6' code={code1}>
                    <Listing>
                        <ListingItem>
                            <span>Item 1</span>
                        </ListingItem>
                        <ListingItem>
                            <span>Item 2</span>
                        </ListingItem>
                        <ListingItem>
                            <span>Item 3</span>
                        </ListingItem>
                    </Listing>
                </BoxCode>


                <BoxCode title='Listado + Tag' classBox='gray6' code={code2}>
                    <Listing>
                        <ListingItem>
                            <span>Item 1</span>
                            <div className="flex g15">
                                <Tag>Tag 1</Tag>
                                <Tag>Tag 2</Tag>
                                <Tag>Tag 3</Tag>
                            </div>
                        </ListingItem>
                        <ListingItem>
                            <span>Item 2</span>
                            <div className="flex g15">
                                <Tag>Tag 1</Tag>
                                <Tag>Tag 2</Tag>
                            </div>
                        </ListingItem>
                        <ListingItem>
                            <span>Item 3</span>
                            <div className="flex g15">
                                <Tag>Tag 1</Tag>
                                <Tag>Tag 2</Tag>
                                <Tag>Tag 3</Tag>
                                <Tag>Tag 4</Tag>
                                <Tag>Tag 5</Tag>
                            </div>
                        </ListingItem>
                    </Listing>
                </BoxCode>
            </div>
        </Main>
    );
}