
import React, {Fragment, useRef, useEffect, useState} from "react";

export default function RadioLabel({ label='', name='', value='', line=true, className='', defaultChecked=false, onChecked=()=>{}, Checked=null, disabled=false }) {

  const id = Math.floor(Math.random() * (99999 - 0 + 1) + 0);
  const [check, setCheck] = useState(null);

  useEffect(() => {
    setCheck(Checked);
  }, [Checked]);
  
  return (
    <>
      {check != null ?
        <input type="radio" name={name} id={`radio-label-${id}`} className="input-radio-label" value={value} onChange={({target}) => onChecked(target.checked)} checked={check} disabled={disabled} /> : 
        <input type="radio" name={name} id={`radio-label-${id}`} className="input-radio-label" value={value} onChange={({target}) => onChecked(target.checked)} defaultChecked={defaultChecked} disabled={disabled} />
      }
      <label for={`radio-label-${id}`} className={`radio-label ${className} ${line ? 'line' : 'no-line'}`}>{label}</label>
    </>
  ); 
}

