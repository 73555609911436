import React from "react";

export default function Card({children, className, dir, padding, disabled, type, onDragEnter=()=>{}, onDragLeave=()=>{} }) {
    dir === undefined ? (dir = "") : (dir = dir);
    padding === undefined ? (padding = "int") : (padding = "");
    return (
        <div className={`card ${className} ${dir} ${padding}`} disabled={disabled} onDragEnter={onDragEnter} onDragLeave={onDragLeave}>
            {children}
        </div>
    );
}
